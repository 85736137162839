
.NavigationItem {
    /* margin: 0 4%; */

    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
    text-align: center;
}

.NavigationItem a {
    color: #f5f5f5;
    text-decoration: none;
    width: 100%;
    padding: 8px 5px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    display: block;
    height: 100%;
    padding: 16px 10px;
    font-size: 17px;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: white;
    border-top: 3px solid rgb(255, 113, 19);
}

@media (max-width: 767px) {
    .NavigationItem {
        padding: 0;
        margin-bottom: 17px;
    }

    .NavigationItem a {
        padding: 0 8px;
        font-size: 21px;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        color: white;
        border-top: 3px solid transparent;
        border-bottom: 3px solid rgb(255, 113, 19);
    }
}

@media screen and (max-height: 450px) {
    .NavigationItem {
        margin-bottom: 12px;
    }    

    .NavigationItem a {
        font-size: 18px;
    }
}