.VideoBox {
    max-width: 780px;
    max-height: 440px;
    width: 100vw;
    height: 56.4vw;
    margin: 75px auto 95px;

    position: relative;
    z-index: 5;
    box-sizing: border-box;
}

.SeeMoreButtonContainer {
    position: absolute;
    top: 100%;
    left: 100%;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    transform: translate(-52%, -66%);
    z-index: 20;
}

.DotNavigationContainer {
    position: relative;
    margin-top: -50px; 
    margin-bottom: 50px;
    z-index: 1;
}

@media (max-width: 1023px) {
    .DesktopOnly {
        display: none;
    }
}

@media (max-width: 780px) {
    .VideoBox {
        margin: 0;
    }

    .DotNavigationContainer {
        display: none;
    }
}

@media (max-width: 480px) {
    .VideoBox {
        height: 80vw;
    }
}