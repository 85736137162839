.Input {
    width: 100%;
    padding: 7px 10px;
    box-sizing: border-box;
}

.InputElement {
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: white;
    font: inherit;
    padding: 10px 15px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    height: 47px;
    resize: none;
    opacity: 0.9;
}

.InputElement:focus {
    outline: none;
    background-color: #f5f5f5;
}

.InputElement:placeholder-shown {
    text-transform: uppercase;
    text-align: center;
    
}

.InputElement::placeholder {
    opacity: 1;
    color: #4C4A80;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
}

.InputElement:focus::placeholder {
    color: transparent;
}

.InputErr {
    box-shadow: 0px 0px 1px 1px #f01010;
}

.ErrMsg {
    color: #f01010;
}