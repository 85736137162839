.CloudBackground {
    width: 100%;
    padding: 0;
    position: relative;
    background-image: linear-gradient(to bottom, #5ECBEE, #E3F8FF 90%);
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: hidden;
}


.CloudTopLeft {
    position: absolute;
    left: 0%;
    top: 0%;

    transform: translate(-50%, -20%);
    /* clip-path: polygon(0 20%, 100% 20%, 100% 100%, 0 100%); */
    animation: topLeftAnimation 24s infinite ease-in-out;
}

.CloudTopRight {
    /* width: 18%; */

    position: absolute;
    left: 100%;
    top: 0%;

    transform: translate(-50%, -70%);
    /* clip-path: polygon(0 40%, 100% 40%, 100% 100%, 0 100%); */
    animation: topRightAnimation 24s infinite ease-in-out;
}

.CloudBottomLeft {

    position: absolute;
    left: 0%;
    top: 100%;

    transform: translate(-75%, -75%);
    /* clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%); */
    animation: bottomLeftAnimation 24s infinite ease-in-out;
}

.CloudBottomRight {
    width: 42%;

    position: absolute;
    left: 100%;
    top: 100%;

    transform: translate(-100%, -90%);
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0 90%); */

    animation: bottomRightAnimation 24s infinite ease-in-out;

}

@keyframes topLeftAnimation {
    0% {
        transform: translate(-50%, -20%);
    }

    50% {
        transform: translate(calc(-50% + 500px), -20%);
    }

    100% {
        transform: translate(-50%, -20%);
    }
}

@keyframes topRightAnimation {
    0% {
        transform: translate(-50%, -70%);
    }

    50% {
        transform: translate(calc(-50% + 240px), -70%);
    }

    100% {
        transform: translate(-50%, -70%);
    }
}

@keyframes bottomLeftAnimation {
    0% {
        transform: translate(-75%, -75%);
    }

    50% {
        transform: translate(calc(-75% + 375px), -75%);
    }

    100% {
        transform: translate(-75%, -75%);
    }
}

@keyframes bottomRightAnimation {
    0% {
        transform: translate(-100%, -90%);
    }

    50% {
        transform: translate(calc(-100% + 200px), -90%);
    }

    100% {
        transform: translate(-100%, -90%);
    }
}

@media (max-width: 767px) {
    .CloudTopLeft, .CloudTopRight, .CloudBottomLeft, .CloudBottomRight {
        animation: none;
    }
}