.TabsBar {
    height: 90px;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    z-index: 1;
    position: relative;
    max-width: 100%;
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;   /* IE 10+ */
}

.TabsBar::-webkit-scrollbar {
    display:none;
}

.TabsContainer {
    width: 100%;
    min-width: 450px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    box-shadow: 0 10px 20px -10px #aaa;
    position: relative;
}

.ContactUsLink {
    position: absolute;
    top: 40%;
    left: 80%;
    transform: translate(-50%, -50%);
    color: #888;
    font-size: 14px;
}

.ContactUsLink img {
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .ContactUsLink {
        display: none;
    }
}