.DotNavigation {
    height: 10px;
}

.Dot {
    margin: 0px 5px;
}

.Clickable {
    cursor: pointer;
}

.CustomDot {
    border-radius: 50%;
    display: inline-block;
}