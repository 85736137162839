.Toolbar {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2B286C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14%;
    box-sizing: border-box;
    color: white;
    position: relative;
    z-index: 100;
}

.Logo {
    order: 1;
    max-height: 80%;
}

.Logo img {
    max-height: 48px;
}

.Toolbar nav {
    height: 100%;
    width: 50%;
    max-width: 420px;
    min-width: 360px;

    order: 2;
}

.LanguageSelectContainer {
    order: 3;

    width: 150px;
    height: 66%;
}

.MenuIconContainer {
    order: 3;

    display: flex;
    width: 60px;
    height: 60px;
}

@media(max-width: 75em) {
    .Toolbar {
        padding: 0 2%;
    }
}

@media(max-width: 767px) {
    .Toolbar {
        padding: 0;
    }
    
    .DesktopAndTablet {
        display: none;
    }

    .LanguageSelectContainer {
        order: 1;
        height: 60px;
        width: 60px;
    }

    .Logo {
        order: 2;
    }
}

@media (min-width: 768px) {
    .MobileOnly {
        display: none;
    }
}