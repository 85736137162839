.Item {
    width: calc(95vw - 90px);
    max-width: 300px;
    min-height: 446px;
    background-color: white;
    text-align: center;
    padding: 40px 20px 0 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px #ccc;
    cursor: pointer;
}

.Item img {
    max-width: 100%;
    height: 187px;
}

.Item h3 {
    margin-bottom: 15px;
}

.Item p {
    line-height: 20px;
    color: #666;
    font-size: 14px;
}

.Item a {
    text-decoration: none;
}

.Link {
    margin: 30px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
}

@media (max-width: 767px) {
    .Item {
        box-shadow: none;
    }
}