.TimelineElement {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

}

.LineAndDot {
    height: 100%;
    width: 1px;

    position: absolute;
    top: 6px;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.3);
}

.TimelineDot {
    position: absolute;
    /* top: 0; 
    left: 50%; */
    transform: translateX(-50%);
}

.OnlyTimelineDot {
    position: absolute;
    top: 6px; 
    left: 50%;
    transform: translateX(-50%);
}

.ElementContent {
    width: 47%;
    margin-right: 0;
    height: 100%;
    /* background-color: rgba(91, 248, 91, 0.2); */
    text-align: left;
}

.ElementContent h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.ElementContent p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
} 

.Left {
    align-items: flex-start;
}

.Left .ElementContent {
    text-align: right;
}

@media (max-width: 767px) {
    .ElementContent {
        margin-bottom: 20px;
        width: 94%;
    }

    .LineAndDot, .OnlyTimelineDot{
        left: 0;
    }

    .Left {
        align-items: flex-end;
    }

    .Left .ElementContent {
        text-align: left;
    }
}