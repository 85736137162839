.AboutUsSection {
    background-image: linear-gradient(to bottom, #2B286C, #4E4997);
    position: relative;
}

.ButtonArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.AboutUsButton {
    order: 1;
}

.ImageContainer {
    width: 450px;
    order: 2;
    /* background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%); */
}

.ImageContainer img {
    max-width: 360px;
    height: auto;
    display: block;
    margin: 0 auto;
    background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%);
}

.MalivaHeroesButton {
    order: 3;
}

.EasyOpenContainer {
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translateX(-100%);
    display: flex;
}

/* .DeactivateParallax {
    position: absolute;
    top: 120%;
    width: 100%;
    height: 10px;
} */


@media (max-width: 1023px) {
    .ButtonArea {
        flex-wrap: wrap;
    }

    .ImageContainer {
        order: 3;
        width: 100%;
        margin-top: 20px;
    }

    .ImageContainer img {
        max-width: 300px;
    }

    .MalivaHeroesButton {
        order: 2;
    }
}

@media (max-width: 480px) {
    .ImageContainer img {
        max-width: 240px;
    }

    .EasyOpenContainer {
        display: none;
    }
}