
.Slide {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
}

.ImageContainer {
    width: 38%;
    order: 2;
    max-height: 240px;
}

.ImageContainer img {
    max-height: 240px;
    max-width: 100%;
    height: auto;
    width: auto;
}

.SlideText {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;
}

.SlideText a,
.SlideText a:active,
.SlideText a:visited {
    color: white;
    text-decoration: none;
}

.SlideText h3 {
    font-size: 28px;
}

.SlideText p {
    font-size: 15px;
    line-height: 21px;
}

@media (max-width: 1023px) {
    .Slide {
        flex-flow: column;
        justify-content: center;
    }

    .ImageContainer {
        order: 1;
        width: 100%;
    }

    .SlideText {
        order: 2;
        width: 100%;
        text-align: center;
        padding: 0;
    }
}

@media (max-width: 400px) {
    .ImageContainer {
        width: 60%;
    }

    .ImageContainer img {
        max-height: 180px;
    }
}