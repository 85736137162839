.PortfolioContainer {
    width: 100%;
    text-align: center;
    padding-top: 30px;
    color: white;
    padding-bottom: 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.CarouselContainer {
    width: 935px;
    position: relative;
    padding: 15px 55px 0;
}

.PrevButton {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
    cursor: pointer;
}

.NextButton {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: rotate(180);
    transform: translate(-100%, -50%);
    cursor: pointer;
}

.DotNavigationContainer {
    margin-top: 50px; 
}

@media (max-width: 1023px) {
    .CarouselContainer {
        width: 90%;
        max-width: 640px;
        margin: auto;
    }
}

@media (max-width: 400px) {
    .CarouselContainer {
        padding: 15px 0 0;
    }

    .PrevButton, .NextButton {
        top: 27%;
        width: 45px;
        height: 45px;
    }

    .DotNavigationContainer {
        margin-top: 10px;
    }
}