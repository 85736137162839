@media (max-width: 767px) {
    .SnowfallBackground {
        display: none;
    }
}  
  
  .Snowflake {
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
  }
  .Snowflake:nth-of-type(even) {
    background-color: white;
    border-color: #fff;
  }
  .Snowflake:nth-of-type(odd) {
    background-color: #bbb;
    border-color: #bbb;
  }
  .Snowflake:nth-of-type(1) {
    top: -91.89600579px;
    left: 53.91734272vw;
    animation: snowfall 11.85447841s linear 2.1248591s infinite normal, snowflake-shake 15.88310886s ease-in-out 0.38383144s infinite alternate;
  }
  .Snowflake:nth-of-type(2) {
    top: -126.79125954px;
    left: 53.62221136vw;
    animation: snowfall 10.62388664s linear 1.80441275s infinite normal, snowflake-shake 13.15047108s ease-in-out 1.17189338s infinite alternate;
  }
  .Snowflake:nth-of-type(3) {
    top: -59.71978673px;
    left: 38.70695342vw;
    animation: snowfall 10.59388036s linear 3.48576319s infinite normal, snowflake-shake 12.75471348s ease-in-out 1.58160024s infinite alternate;
  }
  .Snowflake:nth-of-type(4) {
    top: -58.99705135px;
    left: 82.56302268vw;
    animation: snowfall 8.88517308s linear 0.56543303s infinite normal, snowflake-shake 14.13686496s ease-in-out 3.83489004s infinite alternate;
  }
  .Snowflake:nth-of-type(5) {
    top: -65.73792732px;
    left: 58.19395654vw;
    animation: snowfall 8.620978s linear 1.8829082s infinite normal, snowflake-shake 15.58002726s ease-in-out 2.46174219s infinite alternate;
  }
  .Snowflake:nth-of-type(6) {
    top: -59.90151898px;
    left: 44.07245404vw;
    animation: snowfall 8.86552256s linear 1.40360384s infinite normal, snowflake-shake 15.86492341s ease-in-out 3.75346596s infinite alternate;
  }
  .Snowflake:nth-of-type(7) {
    top: -88.56943809px;
    left: 17.75031549vw;
    animation: snowfall 8.66631156s linear 2.4690489s infinite normal, snowflake-shake 15.77061672s ease-in-out 4.65232885s infinite alternate;
  }
  .Snowflake:nth-of-type(8) {
    top: -144.72764798px;
    left: 61.9875859vw;
    animation: snowfall 10.4327709s linear 2.18599154s infinite normal, snowflake-shake 14.78707809s ease-in-out 1.53982345s infinite alternate;
  }
  .Snowflake:nth-of-type(9) {
    top: -68.76241232px;
    left: 55.07729087vw;
    animation: snowfall 10.59443035s linear 0.37677285s infinite normal, snowflake-shake 12.8909684s ease-in-out 4.29362935s infinite alternate;
  }
  .Snowflake:nth-of-type(10) {
    top: -73.93387084px;
    left: 91.16151008vw;
    animation: snowfall 11.03208457s linear 4.81421229s infinite normal, snowflake-shake 13.16190057s ease-in-out 1.08904849s infinite alternate;
  }
  .Snowflake:nth-of-type(11) {
    top: -116.99470225px;
    left: 14.10153321vw;
    animation: snowfall 9.44172806s linear 4.31805293s infinite normal, snowflake-shake 12.914315s ease-in-out 3.04431871s infinite alternate;
  }
  .Snowflake:nth-of-type(12) {
    top: -72.74129046px;
    left: 38.45972653vw;
    animation: snowfall 10.03032728s linear 0.54616691s infinite normal, snowflake-shake 13.2651969s ease-in-out 3.04622193s infinite alternate;
  }
  .Snowflake:nth-of-type(13) {
    top: -50.0654258px;
    left: 25.27006485vw;
    animation: snowfall 11.65840855s linear 4.99888288s infinite normal, snowflake-shake 12.56145235s ease-in-out 4.39497306s infinite alternate;
  }
  .Snowflake:nth-of-type(14) {
    top: -71.43772246px;
    left: 73.26651955vw;
    animation: snowfall 10.07283783s linear 0.14873134s infinite normal, snowflake-shake 12.50294559s ease-in-out 0.26947427s infinite alternate;
  }
  .Snowflake:nth-of-type(15) {
    top: -61.25323065px;
    left: 66.88748357vw;
    animation: snowfall 8.53728613s linear 2.60024366s infinite normal, snowflake-shake 12.06892508s ease-in-out 3.96277947s infinite alternate;
  }
  .Snowflake:nth-of-type(16) {
    top: -90.76242994px;
    left: 31.75768302vw;
    animation: snowfall 8.78557317s linear 4.2592633s infinite normal, snowflake-shake 13.75675215s ease-in-out 1.43147614s infinite alternate;
  }
  .Snowflake:nth-of-type(17) {
    top: -92.67347757px;
    left: 36.72132131vw;
    animation: snowfall 8.07763823s linear 3.8941454s infinite normal, snowflake-shake 13.96680484s ease-in-out 2.96852954s infinite alternate;
  }
  .Snowflake:nth-of-type(18) {
    top: -100.03197565px;
    left: 75.66025736vw;
    animation: snowfall 10.09115658s linear 1.54397749s infinite normal, snowflake-shake 15.62386662s ease-in-out 0.47570317s infinite alternate;
  }
  .Snowflake:nth-of-type(19) {
    top: -67.27746897px;
    left: 10.47989741vw;
    animation: snowfall 8.90335519s linear 0.13685823s infinite normal, snowflake-shake 14.6304789s ease-in-out 1.82825253s infinite alternate;
  }
  .Snowflake:nth-of-type(20) {
    top: -73.67410979px;
    left: 20.96105421vw;
    animation: snowfall 11.2297806s linear 4.24881653s infinite normal, snowflake-shake 13.60466904s ease-in-out 2.24123428s infinite alternate;
  }
  .Snowflake:nth-of-type(21) {
    top: -53.34287892px;
    left: 32.53583863vw;
    animation: snowfall 10.56325742s linear 1.21869329s infinite normal, snowflake-shake 14.27120591s ease-in-out 3.19165486s infinite alternate;
  }
  .Snowflake:nth-of-type(22) {
    top: -75.43887371px;
    left: 45.24905521vw;
    animation: snowfall 10.59550461s linear 2.1457754s infinite normal, snowflake-shake 12.17332803s ease-in-out 1.55634966s infinite alternate;
  }
  .Snowflake:nth-of-type(23) {
    top: -50.02952345px;
    left: 77.49650574vw;
    animation: snowfall 9.0933826s linear 0.98722379s infinite normal, snowflake-shake 13.76601188s ease-in-out 3.55814369s infinite alternate;
  }
  .Snowflake:nth-of-type(24) {
    top: -118.8196576px;
    left: 15.1250061vw;
    animation: snowfall 8.3228832s linear 4.13842688s infinite normal, snowflake-shake 13.98922431s ease-in-out 4.94379025s infinite alternate;
  }
  .Snowflake:nth-of-type(25) {
    top: -106.36059058px;
    left: 18.34143398vw;
    animation: snowfall 11.21279916s linear 3.59650576s infinite normal, snowflake-shake 15.19090228s ease-in-out 0.63202205s infinite alternate;
  }
  .Snowflake:nth-of-type(26) {
    top: -103.9081511px;
    left: 76.04557065vw;
    animation: snowfall 10.06958352s linear 0.82815266s infinite normal, snowflake-shake 13.58156646s ease-in-out 2.94531432s infinite alternate;
  }
  .Snowflake:nth-of-type(27) {
    top: -96.56792043px;
    left: 38.66367394vw;
    animation: snowfall 9.00354917s linear 2.10232644s infinite normal, snowflake-shake 14.68854499s ease-in-out 0.62160544s infinite alternate;
  }
  .Snowflake:nth-of-type(28) {
    top: -107.03417342px;
    left: 87.83574727vw;
    animation: snowfall 11.77820856s linear 4.81544263s infinite normal, snowflake-shake 13.96793579s ease-in-out 3.05804187s infinite alternate;
  }
  .Snowflake:nth-of-type(29) {
    top: -59.36734149px;
    left: 87.05357291vw;
    animation: snowfall 10.94015694s linear 2.72604542s infinite normal, snowflake-shake 14.3592206s ease-in-out 4.09207837s infinite alternate;
  }
  .Snowflake:nth-of-type(30) {
    top: -96.45396155px;
    left: 90.95468561vw;
    animation: snowfall 8.1671584s linear 2.24963713s infinite normal, snowflake-shake 12.72817532s ease-in-out 0.36107683s infinite alternate;
  }
  .Snowflake:nth-of-type(31) {
    top: -70.74028783px;
    left: 72.48118152vw;
    animation: snowfall 9.13256301s linear 4.22565595s infinite normal, snowflake-shake 14.89109571s ease-in-out 0.13266976s infinite alternate;
  }
  .Snowflake:nth-of-type(32) {
    top: -109.53645185px;
    left: 75.8656403vw;
    animation: snowfall 9.5747906s linear 0.50776629s infinite normal, snowflake-shake 15.72481062s ease-in-out 2.7663965s infinite alternate;
  }
  .Snowflake:nth-of-type(33) {
    top: -59.85462265px;
    left: 35.42278784vw;
    animation: snowfall 11.38085051s linear 4.58791699s infinite normal, snowflake-shake 13.4995816s ease-in-out 3.39198484s infinite alternate;
  }
  .Snowflake:nth-of-type(34) {
    top: -115.76825443px;
    left: 53.40810286vw;
    animation: snowfall 10.59460176s linear 4.51999661s infinite normal, snowflake-shake 12.82248983s ease-in-out 0.68297339s infinite alternate;
  }
  .Snowflake:nth-of-type(35) {
    top: -114.4489842px;
    left: 67.53525253vw;
    animation: snowfall 8.34607359s linear 4.02450481s infinite normal, snowflake-shake 12.00968279s ease-in-out 1.36582013s infinite alternate;
  }
  .Snowflake:nth-of-type(36) {
    top: -122.77680406px;
    left: 16.6843136vw;
    animation: snowfall 8.01564148s linear 2.34619104s infinite normal, snowflake-shake 12.25680511s ease-in-out 0.00468633s infinite alternate;
  }
  .Snowflake:nth-of-type(37) {
    top: -142.34512913px;
    left: 87.09081389vw;
    animation: snowfall 11.36892644s linear 3.28190844s infinite normal, snowflake-shake 12.14963203s ease-in-out 0.01506589s infinite alternate;
  }
  .Snowflake:nth-of-type(38) {
    top: -148.95235876px;
    left: 45.40879557vw;
    animation: snowfall 8.24060604s linear 1.08959339s infinite normal, snowflake-shake 15.0931223s ease-in-out 2.55177918s infinite alternate;
  }
  .Snowflake:nth-of-type(39) {
    top: -75.12537282px;
    left: 95.94237935vw;
    animation: snowfall 9.57004979s linear 3.84890408s infinite normal, snowflake-shake 13.96838672s ease-in-out 2.40878654s infinite alternate;
  }
  .Snowflake:nth-of-type(40) {
    top: -82.52055056px;
    left: 32.7144111vw;
    animation: snowfall 10.72975047s linear 0.57518944s infinite normal, snowflake-shake 12.59284153s ease-in-out 0.00492869s infinite alternate;
  }
  .Snowflake:nth-of-type(41) {
    top: -102.57060255px;
    left: 97.35413535vw;
    animation: snowfall 8.6775553s linear 1.51641412s infinite normal, snowflake-shake 15.91348205s ease-in-out 0.00188695s infinite alternate;
  }
  .Snowflake:nth-of-type(42) {
    top: -126.83640525px;
    left: 5.75678608vw;
    animation: snowfall 11.20433021s linear 0.74431962s infinite normal, snowflake-shake 15.81458002s ease-in-out 3.67585194s infinite alternate;
  }
  .Snowflake:nth-of-type(43) {
    top: -104.67128806px;
    left: 39.10317096vw;
    animation: snowfall 9.02549757s linear 2.84883457s infinite normal, snowflake-shake 14.79678709s ease-in-out 1.08559828s infinite alternate;
  }
  .Snowflake:nth-of-type(44) {
    top: -130.64284274px;
    left: 18.86857233vw;
    animation: snowfall 9.32699008s linear 3.33293111s infinite normal, snowflake-shake 12.58103587s ease-in-out 2.67086793s infinite alternate;
  }
  .Snowflake:nth-of-type(45) {
    top: -107.77835251px;
    left: 77.3432497vw;
    animation: snowfall 11.58819811s linear 0.22941595s infinite normal, snowflake-shake 13.26764544s ease-in-out 4.31627462s infinite alternate;
  }
  .Snowflake:nth-of-type(46) {
    top: -58.47480062px;
    left: 62.48188553vw;
    animation: snowfall 8.5376304s linear 3.64456969s infinite normal, snowflake-shake 14.47180271s ease-in-out 1.71666948s infinite alternate;
  }
  .Snowflake:nth-of-type(47) {
    top: -80.24802302px;
    left: 90.02892345vw;
    animation: snowfall 10.401052s linear 4.71586777s infinite normal, snowflake-shake 12.08823415s ease-in-out 2.81862705s infinite alternate;
  }
  .Snowflake:nth-of-type(48) {
    top: -126.589372px;
    left: 42.9805416vw;
    animation: snowfall 9.69469368s linear 0.46789756s infinite normal, snowflake-shake 14.15702479s ease-in-out 1.99052153s infinite alternate;
  }
  .Snowflake:nth-of-type(49) {
    top: -143.97193022px;
    left: 14.57213082vw;
    animation: snowfall 9.03878119s linear 4.4465543s infinite normal, snowflake-shake 14.04785507s ease-in-out 1.57200533s infinite alternate;
  }
  .Snowflake:nth-of-type(50) {
    top: -69.07080353px;
    left: 4.99686097vw;
    animation: snowfall 10.75948207s linear 0.16501986s infinite normal, snowflake-shake 15.38731886s ease-in-out 0.27565912s infinite alternate;
  }
  .Snowflake:nth-of-type(51) {
    top: -115.35709526px;
    left: 98.40463549vw;
    animation: snowfall 8.48070534s linear 2.83739541s infinite normal, snowflake-shake 12.50995528s ease-in-out 2.87511112s infinite alternate;
  }
  .Snowflake:nth-of-type(52) {
    top: -70.28277556px;
    left: 56.57505455vw;
    animation: snowfall 9.25410797s linear 1.94447368s infinite normal, snowflake-shake 13.99091836s ease-in-out 3.08104425s infinite alternate;
  }
  .Snowflake:nth-of-type(53) {
    top: -99.66988223px;
    left: 8.65447166vw;
    animation: snowfall 11.62861845s linear 2.94837611s infinite normal, snowflake-shake 12.1195666s ease-in-out 4.62985486s infinite alternate;
  }
  .Snowflake:nth-of-type(54) {
    top: -84.34617874px;
    left: 40.5656333vw;
    animation: snowfall 8.8806556s linear 2.03212013s infinite normal, snowflake-shake 15.93062176s ease-in-out 1.55227678s infinite alternate;
  }
  .Snowflake:nth-of-type(55) {
    top: -125.24419455px;
    left: 75.52067588vw;
    animation: snowfall 9.65099624s linear 2.79925646s infinite normal, snowflake-shake 13.64302479s ease-in-out 1.64169634s infinite alternate;
  }
  .Snowflake:nth-of-type(56) {
    top: -140.32806615px;
    left: 32.11669604vw;
    animation: snowfall 10.10594135s linear 4.25168108s infinite normal, snowflake-shake 15.00791804s ease-in-out 2.69655787s infinite alternate;
  }
  .Snowflake:nth-of-type(57) {
    top: -144.00402896px;
    left: 39.43060219vw;
    animation: snowfall 11.5225623s linear 3.67029919s infinite normal, snowflake-shake 12.4766537s ease-in-out 1.91360713s infinite alternate;
  }
  .Snowflake:nth-of-type(58) {
    top: -56.7023953px;
    left: 73.13779699vw;
    animation: snowfall 11.60705292s linear 3.0980101s infinite normal, snowflake-shake 12.5154604s ease-in-out 0.26273098s infinite alternate;
  }
  .Snowflake:nth-of-type(59) {
    top: -56.1092128px;
    left: 57.88806777vw;
    animation: snowfall 11.08986771s linear 0.31172403s infinite normal, snowflake-shake 13.04870989s ease-in-out 4.75493536s infinite alternate;
  }
  .Snowflake:nth-of-type(60) {
    top: -77.00598708px;
    left: 70.34681338vw;
    animation: snowfall 11.67537236s linear 1.12003808s infinite normal, snowflake-shake 14.93287781s ease-in-out 3.62774448s infinite alternate;
  }
  .Snowflake:nth-of-type(61) {
    top: -73.21741192px;
    left: 59.57395756vw;
    animation: snowfall 9.91934423s linear 3.54349975s infinite normal, snowflake-shake 12.62486742s ease-in-out 2.24104346s infinite alternate;
  }
  .Snowflake:nth-of-type(62) {
    top: -125.03282449px;
    left: 74.58544582vw;
    animation: snowfall 8.07176684s linear 3.86950284s infinite normal, snowflake-shake 14.03603588s ease-in-out 4.57758474s infinite alternate;
  }
  .Snowflake:nth-of-type(63) {
    top: -141.37632989px;
    left: 29.99795463vw;
    animation: snowfall 10.71135893s linear 4.56334222s infinite normal, snowflake-shake 12.24481444s ease-in-out 3.53033995s infinite alternate;
  }
  .Snowflake:nth-of-type(64) {
    top: -100.6736978px;
    left: 1.1215167vw;
    animation: snowfall 10.49753088s linear 1.6743702s infinite normal, snowflake-shake 12.03089649s ease-in-out 4.8385111s infinite alternate;
  }
  .Snowflake:nth-of-type(65) {
    top: -82.05324823px;
    left: 73.77959688vw;
    animation: snowfall 11.20079952s linear 1.63271561s infinite normal, snowflake-shake 13.42794122s ease-in-out 2.40789082s infinite alternate;
  }
  .Snowflake:nth-of-type(66) {
    top: -112.13469118px;
    left: 79.80011465vw;
    animation: snowfall 9.15245635s linear 1.64654246s infinite normal, snowflake-shake 14.27636796s ease-in-out 4.15296953s infinite alternate;
  }
  .Snowflake:nth-of-type(67) {
    top: -78.16062921px;
    left: 31.25867339vw;
    animation: snowfall 11.31390324s linear 0.98608101s infinite normal, snowflake-shake 15.4469254s ease-in-out 1.36369027s infinite alternate;
  }
  .Snowflake:nth-of-type(68) {
    top: -51.94206544px;
    left: 30.38317801vw;
    animation: snowfall 9.9557925s linear 3.19326194s infinite normal, snowflake-shake 12.31008568s ease-in-out 2.49350775s infinite alternate;
  }
  .Snowflake:nth-of-type(69) {
    top: -126.91005728px;
    left: 55.18941281vw;
    animation: snowfall 8.74424623s linear 4.94767524s infinite normal, snowflake-shake 13.51645347s ease-in-out 1.99621497s infinite alternate;
  }
  .Snowflake:nth-of-type(70) {
    top: -113.83932311px;
    left: 15.59300615vw;
    animation: snowfall 10.83493048s linear 3.52605579s infinite normal, snowflake-shake 14.22210339s ease-in-out 2.78787937s infinite alternate;
  }
  .Snowflake:nth-of-type(71) {
    top: -100.99768196px;
    left: 39.47309855vw;
    animation: snowfall 10.75569079s linear 1.84872751s infinite normal, snowflake-shake 14.37463193s ease-in-out 2.34717235s infinite alternate;
  }
  .Snowflake:nth-of-type(72) {
    top: -103.02665091px;
    left: 87.61398722vw;
    animation: snowfall 11.4888389s linear 4.35746723s infinite normal, snowflake-shake 12.67815295s ease-in-out 0.37170691s infinite alternate;
  }
  .Snowflake:nth-of-type(73) {
    top: -65.72688283px;
    left: 14.8913051vw;
    animation: snowfall 11.45698327s linear 0.24130712s infinite normal, snowflake-shake 12.75093604s ease-in-out 3.91684396s infinite alternate;
  }
  .Snowflake:nth-of-type(74) {
    top: -61.98009627px;
    left: 42.8816707vw;
    animation: snowfall 10.59132996s linear 2.66716507s infinite normal, snowflake-shake 12.22268858s ease-in-out 1.65254696s infinite alternate;
  }
  .Snowflake:nth-of-type(75) {
    top: -126.54859111px;
    left: 26.51213145vw;
    animation: snowfall 9.39274009s linear 3.26568927s infinite normal, snowflake-shake 15.19376586s ease-in-out 4.59850419s infinite alternate;
  }
  .Snowflake:nth-of-type(76) {
    top: -79.70230944px;
    left: 61.21192429vw;
    animation: snowfall 10.78938216s linear 4.4763324s infinite normal, snowflake-shake 13.99459733s ease-in-out 0.74556437s infinite alternate;
  }
  .Snowflake:nth-of-type(77) {
    top: -149.81941889px;
    left: 34.01814258vw;
    animation: snowfall 8.79729408s linear 3.88211403s infinite normal, snowflake-shake 13.77576429s ease-in-out 1.43506565s infinite alternate;
  }
  .Snowflake:nth-of-type(78) {
    top: -147.57065886px;
    left: 43.01962938vw;
    animation: snowfall 8.26181538s linear 0.08805551s infinite normal, snowflake-shake 15.80888319s ease-in-out 1.62761307s infinite alternate;
  }
  .Snowflake:nth-of-type(79) {
    top: -90.48393734px;
    left: 26.23567455vw;
    animation: snowfall 9.95105439s linear 1.78243686s infinite normal, snowflake-shake 12.2376956s ease-in-out 3.84500686s infinite alternate;
  }
  .Snowflake:nth-of-type(80) {
    top: -72.64930921px;
    left: 64.14546113vw;
    animation: snowfall 8.06763965s linear 4.17151181s infinite normal, snowflake-shake 12.18134699s ease-in-out 0.05250472s infinite alternate;
  }
  .Snowflake:nth-of-type(81) {
    top: -88.91252919px;
    left: 2.12120113vw;
    animation: snowfall 11.50029076s linear 1.80373758s infinite normal, snowflake-shake 13.86390066s ease-in-out 0.44589638s infinite alternate;
  }
  .Snowflake:nth-of-type(82) {
    top: -119.34273121px;
    left: 28.70949168vw;
    animation: snowfall 11.07246626s linear 2.9994034s infinite normal, snowflake-shake 14.04253498s ease-in-out 0.34773418s infinite alternate;
  }
  .Snowflake:nth-of-type(83) {
    top: -149.71888874px;
    left: 14.74797731vw;
    animation: snowfall 8.94078048s linear 3.09244904s infinite normal, snowflake-shake 14.72013437s ease-in-out 0.6976426s infinite alternate;
  }
  .Snowflake:nth-of-type(84) {
    top: -140.21919588px;
    left: 64.41494958vw;
    animation: snowfall 10.58887499s linear 4.19323276s infinite normal, snowflake-shake 13.10797252s ease-in-out 4.55771447s infinite alternate;
  }
  .Snowflake:nth-of-type(85) {
    top: -96.85559519px;
    left: 7.335377vw;
    animation: snowfall 10.09683694s linear 0.38476847s infinite normal, snowflake-shake 13.65964371s ease-in-out 0.23773704s infinite alternate;
  }
  .Snowflake:nth-of-type(86) {
    top: -130.15853222px;
    left: 21.85199878vw;
    animation: snowfall 9.49628639s linear 1.20182883s infinite normal, snowflake-shake 13.83871289s ease-in-out 1.25599327s infinite alternate;
  }
  .Snowflake:nth-of-type(87) {
    top: -106.62421315px;
    left: 41.60030415vw;
    animation: snowfall 11.86912485s linear 0.14067442s infinite normal, snowflake-shake 12.44535486s ease-in-out 0.22048939s infinite alternate;
  }
  .Snowflake:nth-of-type(88) {
    top: -134.10033267px;
    left: 31.37861344vw;
    animation: snowfall 11.76172944s linear 0.45690026s infinite normal, snowflake-shake 12.52035855s ease-in-out 2.93878656s infinite alternate;
  }
  .Snowflake:nth-of-type(89) {
    top: -121.12305229px;
    left: 13.57486333vw;
    animation: snowfall 8.79770695s linear 1.19250325s infinite normal, snowflake-shake 13.55553757s ease-in-out 2.1262513s infinite alternate;
  }
  .Snowflake:nth-of-type(90) {
    top: -129.55036652px;
    left: 35.86690537vw;
    animation: snowfall 8.68722708s linear 3.0187784s infinite normal, snowflake-shake 12.24301227s ease-in-out 3.05940335s infinite alternate;
  }
  .Snowflake:nth-of-type(91) {
    top: -126.1056396px;
    left: 91.23957121vw;
    animation: snowfall 11.76796971s linear 1.79081233s infinite normal, snowflake-shake 14.12875277s ease-in-out 1.45182906s infinite alternate;
  }
  .Snowflake:nth-of-type(92) {
    top: -148.86969314px;
    left: 94.3242398vw;
    animation: snowfall 9.62945203s linear 0.14396224s infinite normal, snowflake-shake 12.89947671s ease-in-out 0.91546237s infinite alternate;
  }
  .Snowflake:nth-of-type(93) {
    top: -99.83432322px;
    left: 97.32105232vw;
    animation: snowfall 11.87630867s linear 1.85325004s infinite normal, snowflake-shake 13.75499449s ease-in-out 3.48253212s infinite alternate;
  }
  .Snowflake:nth-of-type(94) {
    top: -112.99859546px;
    left: 67.19123811vw;
    animation: snowfall 8.67747455s linear 0.3426889s infinite normal, snowflake-shake 13.00737221s ease-in-out 4.71864028s infinite alternate;
  }
  .Snowflake:nth-of-type(95) {
    top: -140.77133155px;
    left: 82.95104296vw;
    animation: snowfall 9.4217225s linear 0.70344527s infinite normal, snowflake-shake 14.70406283s ease-in-out 4.13149296s infinite alternate;
  }
  .Snowflake:nth-of-type(96) {
    top: -108.38405663px;
    left: 98.99259189vw;
    animation: snowfall 8.68255531s linear 3.21934608s infinite normal, snowflake-shake 13.61316218s ease-in-out 2.17886539s infinite alternate;
  }
  .Snowflake:nth-of-type(97) {
    top: -113.97666871px;
    left: 22.22450486vw;
    animation: snowfall 9.55948008s linear 1.3446326s infinite normal, snowflake-shake 12.46253457s ease-in-out 0.08825371s infinite alternate;
  }
  .Snowflake:nth-of-type(98) {
    top: -143.01344852px;
    left: 74.2847101vw;
    animation: snowfall 8.87008647s linear 0.6034761s infinite normal, snowflake-shake 13.13626689s ease-in-out 4.34791311s infinite alternate;
  }
  .Snowflake:nth-of-type(99) {
    top: -123.71677482px;
    left: 59.00617188vw;
    animation: snowfall 8.03404231s linear 3.4152244s infinite normal, snowflake-shake 14.15174324s ease-in-out 4.93333142s infinite alternate;
  }
  .Snowflake:nth-of-type(100) {
    top: -148.08269943px;
    left: 10.26698842vw;
    animation: snowfall 8.16016235s linear 3.35972324s infinite normal, snowflake-shake 14.78855021s ease-in-out 4.48121227s infinite alternate;
  }
  .Snowflake:nth-of-type(101) {
    top: -127.97381997px;
    left: 18.74150264vw;
    animation: snowfall 8.49976759s linear 0.51151952s infinite normal, snowflake-shake 14.46623164s ease-in-out 3.58449118s infinite alternate;
  }
  .Snowflake:nth-of-type(102) {
    top: -99.04804298px;
    left: 90.20117745vw;
    animation: snowfall 11.70691371s linear 2.31476862s infinite normal, snowflake-shake 13.94768187s ease-in-out 4.00565816s infinite alternate;
  }
  .Snowflake:nth-of-type(103) {
    top: -74.93028272px;
    left: 39.41579678vw;
    animation: snowfall 9.18841069s linear 4.30150982s infinite normal, snowflake-shake 14.23364831s ease-in-out 1.54668057s infinite alternate;
  }
  .Snowflake:nth-of-type(104) {
    top: -64.81409139px;
    left: 84.44381468vw;
    animation: snowfall 9.47944364s linear 3.37655819s infinite normal, snowflake-shake 12.41493928s ease-in-out 3.95550885s infinite alternate;
  }
  .Snowflake:nth-of-type(105) {
    top: -104.15493721px;
    left: 42.14859327vw;
    animation: snowfall 9.20895094s linear 1.01674597s infinite normal, snowflake-shake 12.66978467s ease-in-out 2.1659681s infinite alternate;
  }
  .Snowflake:nth-of-type(106) {
    top: -65.15661383px;
    left: 45.68253321vw;
    animation: snowfall 9.05262516s linear 3.69232977s infinite normal, snowflake-shake 13.53532128s ease-in-out 0.60914131s infinite alternate;
  }
  .Snowflake:nth-of-type(107) {
    top: -87.14985152px;
    left: 91.4005106vw;
    animation: snowfall 8.00720284s linear 3.66102839s infinite normal, snowflake-shake 12.74474691s ease-in-out 1.42608467s infinite alternate;
  }
  .Snowflake:nth-of-type(108) {
    top: -122.13369218px;
    left: 86.69943815vw;
    animation: snowfall 10.6053517s linear 2.68569068s infinite normal, snowflake-shake 15.52503396s ease-in-out 0.90503121s infinite alternate;
  }
  .Snowflake:nth-of-type(109) {
    top: -84.90005835px;
    left: 75.32616339vw;
    animation: snowfall 8.11371344s linear 0.95894399s infinite normal, snowflake-shake 15.30963079s ease-in-out 4.25225355s infinite alternate;
  }
  .Snowflake:nth-of-type(110) {
    top: -95.25185853px;
    left: 69.69974881vw;
    animation: snowfall 10.79885076s linear 0.79611916s infinite normal, snowflake-shake 14.95178714s ease-in-out 1.83121811s infinite alternate;
  }
  .Snowflake:nth-of-type(111) {
    top: -121.29566973px;
    left: 8.55672259vw;
    animation: snowfall 9.35856774s linear 2.79402363s infinite normal, snowflake-shake 15.46631557s ease-in-out 1.66511925s infinite alternate;
  }
  .Snowflake:nth-of-type(112) {
    top: -64.42983775px;
    left: 12.80750922vw;
    animation: snowfall 10.1449015s linear 0.08199296s infinite normal, snowflake-shake 15.60676173s ease-in-out 4.99160475s infinite alternate;
  }
  .Snowflake:nth-of-type(113) {
    top: -122.30238891px;
    left: 83.27684817vw;
    animation: snowfall 10.76496241s linear 1.05649645s infinite normal, snowflake-shake 15.74526927s ease-in-out 0.13798488s infinite alternate;
  }
  .Snowflake:nth-of-type(114) {
    top: -65.93299477px;
    left: 19.87956023vw;
    animation: snowfall 9.36693779s linear 3.44497593s infinite normal, snowflake-shake 15.08460251s ease-in-out 3.90816466s infinite alternate;
  }
  .Snowflake:nth-of-type(115) {
    top: -109.43381534px;
    left: 12.38590258vw;
    animation: snowfall 10.97199645s linear 2.55354949s infinite normal, snowflake-shake 14.86884023s ease-in-out 0.85954545s infinite alternate;
  }
  .Snowflake:nth-of-type(116) {
    top: -126.66303632px;
    left: 57.6914593vw;
    animation: snowfall 11.63007966s linear 4.51643674s infinite normal, snowflake-shake 12.67293052s ease-in-out 3.37747178s infinite alternate;
  }
  .Snowflake:nth-of-type(117) {
    top: -89.42241895px;
    left: 80.63480926vw;
    animation: snowfall 8.72548949s linear 1.64747948s infinite normal, snowflake-shake 14.41434883s ease-in-out 2.1005935s infinite alternate;
  }
  .Snowflake:nth-of-type(118) {
    top: -148.33089718px;
    left: 92.73500627vw;
    animation: snowfall 9.21410397s linear 2.07274736s infinite normal, snowflake-shake 12.92460245s ease-in-out 4.50670597s infinite alternate;
  }
  .Snowflake:nth-of-type(119) {
    top: -108.14523228px;
    left: 30.09190191vw;
    animation: snowfall 10.16201677s linear 1.59853043s infinite normal, snowflake-shake 13.10069299s ease-in-out 3.71952935s infinite alternate;
  }
  .Snowflake:nth-of-type(120) {
    top: -88.8675951px;
    left: 23.95158756vw;
    animation: snowfall 8.6222826s linear 3.60555218s infinite normal, snowflake-shake 14.47523422s ease-in-out 0.33394415s infinite alternate;
  }
  .Snowflake:nth-of-type(121) {
    top: -73.48098587px;
    left: 34.74452082vw;
    animation: snowfall 9.66028674s linear 0.24506806s infinite normal, snowflake-shake 12.84376017s ease-in-out 2.17308357s infinite alternate;
  }
  .Snowflake:nth-of-type(122) {
    top: -61.22143828px;
    left: 59.1066759vw;
    animation: snowfall 11.2602254s linear 4.73362015s infinite normal, snowflake-shake 14.15683911s ease-in-out 3.68986719s infinite alternate;
  }
  .Snowflake:nth-of-type(123) {
    top: -81.58685478px;
    left: 4.31597751vw;
    animation: snowfall 8.48845942s linear 3.6606992s infinite normal, snowflake-shake 12.11231666s ease-in-out 3.2217828s infinite alternate;
  }
  .Snowflake:nth-of-type(124) {
    top: -60.55225253px;
    left: 96.81649713vw;
    animation: snowfall 8.735505s linear 2.37244859s infinite normal, snowflake-shake 13.97253458s ease-in-out 3.50668329s infinite alternate;
  }
  .Snowflake:nth-of-type(125) {
    top: -109.09564482px;
    left: 14.65578009vw;
    animation: snowfall 11.9096715s linear 0.96420146s infinite normal, snowflake-shake 12.12649825s ease-in-out 0.62518436s infinite alternate;
  }
  .Snowflake:nth-of-type(126) {
    top: -145.40591258px;
    left: 41.94205777vw;
    animation: snowfall 10.92623317s linear 4.81114315s infinite normal, snowflake-shake 12.40639442s ease-in-out 1.83187335s infinite alternate;
  }
  .Snowflake:nth-of-type(127) {
    top: -142.11503314px;
    left: 3.68936293vw;
    animation: snowfall 11.18245717s linear 4.35496678s infinite normal, snowflake-shake 14.97977711s ease-in-out 0.36946047s infinite alternate;
  }
  .Snowflake:nth-of-type(128) {
    top: -147.93773689px;
    left: 33.38689972vw;
    animation: snowfall 9.24194532s linear 2.29148895s infinite normal, snowflake-shake 15.72694065s ease-in-out 2.76699231s infinite alternate;
  }
  .Snowflake:nth-of-type(129) {
    top: -92.63913823px;
    left: 96.9058649vw;
    animation: snowfall 9.62757366s linear 3.1467094s infinite normal, snowflake-shake 14.5635536s ease-in-out 3.67562203s infinite alternate;
  }
  .Snowflake:nth-of-type(130) {
    top: -97.53523654px;
    left: 22.89344807vw;
    animation: snowfall 8.57427575s linear 2.21162053s infinite normal, snowflake-shake 12.60927252s ease-in-out 3.98825309s infinite alternate;
  }
  .Snowflake:nth-of-type(131) {
    top: -129.72578937px;
    left: 18.32780975vw;
    animation: snowfall 8.15154825s linear 2.20196579s infinite normal, snowflake-shake 15.82658127s ease-in-out 1.32136151s infinite alternate;
  }
  .Snowflake:nth-of-type(132) {
    top: -98.02252517px;
    left: 75.02786572vw;
    animation: snowfall 11.91349583s linear 4.4007248s infinite normal, snowflake-shake 12.85890261s ease-in-out 4.29206098s infinite alternate;
  }
  .Snowflake:nth-of-type(133) {
    top: -102.49224538px;
    left: 85.54506439vw;
    animation: snowfall 8.95755072s linear 1.31027646s infinite normal, snowflake-shake 12.79267946s ease-in-out 1.05954542s infinite alternate;
  }
  .Snowflake:nth-of-type(134) {
    top: -128.96675299px;
    left: 84.36251445vw;
    animation: snowfall 9.83692666s linear 4.85869683s infinite normal, snowflake-shake 14.62951942s ease-in-out 1.99689348s infinite alternate;
  }
  .Snowflake:nth-of-type(135) {
    top: -129.99325118px;
    left: 66.62133434vw;
    animation: snowfall 11.43045473s linear 0.06966526s infinite normal, snowflake-shake 13.73770032s ease-in-out 4.97295651s infinite alternate;
  }
  .Snowflake:nth-of-type(136) {
    top: -55.49450246px;
    left: 55.14579428vw;
    animation: snowfall 8.51464349s linear 2.23349117s infinite normal, snowflake-shake 12.89094828s ease-in-out 2.1981021s infinite alternate;
  }
  .Snowflake:nth-of-type(137) {
    top: -131.75296606px;
    left: 99.32244808vw;
    animation: snowfall 9.083909s linear 0.89712086s infinite normal, snowflake-shake 12.35530588s ease-in-out 3.61544188s infinite alternate;
  }
  .Snowflake:nth-of-type(138) {
    top: -63.60245998px;
    left: 55.03137567vw;
    animation: snowfall 10.16438526s linear 3.47116006s infinite normal, snowflake-shake 12.51209814s ease-in-out 3.1390034s infinite alternate;
  }
  .Snowflake:nth-of-type(139) {
    top: -67.64753236px;
    left: 4.0604085vw;
    animation: snowfall 11.47560691s linear 1.41221852s infinite normal, snowflake-shake 13.06091076s ease-in-out 3.62513348s infinite alternate;
  }
  .Snowflake:nth-of-type(140) {
    top: -135.88232631px;
    left: 78.36499548vw;
    animation: snowfall 11.79248668s linear 0.2593816s infinite normal, snowflake-shake 12.83510297s ease-in-out 3.87156763s infinite alternate;
  }
  .Snowflake:nth-of-type(141) {
    top: -147.06164737px;
    left: 75.00054453vw;
    animation: snowfall 9.58354133s linear 1.62123056s infinite normal, snowflake-shake 13.43851825s ease-in-out 2.97673847s infinite alternate;
  }
  .Snowflake:nth-of-type(142) {
    top: -89.15467248px;
    left: 94.25705135vw;
    animation: snowfall 11.41199004s linear 4.78399161s infinite normal, snowflake-shake 14.51215623s ease-in-out 1.00847092s infinite alternate;
  }
  .Snowflake:nth-of-type(143) {
    top: -66.49293059px;
    left: 15.37991758vw;
    animation: snowfall 10.09453227s linear 4.21790516s infinite normal, snowflake-shake 15.481707s ease-in-out 4.66575524s infinite alternate;
  }
  .Snowflake:nth-of-type(144) {
    top: -69.15706805px;
    left: 27.69687021vw;
    animation: snowfall 11.38739908s linear 1.60474166s infinite normal, snowflake-shake 13.40768523s ease-in-out 4.14070059s infinite alternate;
  }
  .Snowflake:nth-of-type(145) {
    top: -97.13917065px;
    left: 5.56005139vw;
    animation: snowfall 10.02852336s linear 3.1996404s infinite normal, snowflake-shake 14.01973791s ease-in-out 4.40313757s infinite alternate;
  }
  .Snowflake:nth-of-type(146) {
    top: -114.92427977px;
    left: 36.97882865vw;
    animation: snowfall 10.59889021s linear 3.97027398s infinite normal, snowflake-shake 15.43347056s ease-in-out 3.54755675s infinite alternate;
  }
  .Snowflake:nth-of-type(147) {
    top: -74.04632402px;
    left: 91.86661733vw;
    animation: snowfall 8.76324249s linear 1.9047338s infinite normal, snowflake-shake 15.01183876s ease-in-out 2.16968622s infinite alternate;
  }
  .Snowflake:nth-of-type(148) {
    top: -133.77617906px;
    left: 22.96080786vw;
    animation: snowfall 9.41229919s linear 4.71675884s infinite normal, snowflake-shake 13.67823119s ease-in-out 3.23031587s infinite alternate;
  }
  .Snowflake:nth-of-type(149) {
    top: -73.66744161px;
    left: 22.27113408vw;
    animation: snowfall 10.91554486s linear 2.28643657s infinite normal, snowflake-shake 13.81485807s ease-in-out 0.54737425s infinite alternate;
  }
  .Snowflake:nth-of-type(150) {
    top: -127.24164075px;
    left: 87.54597301vw;
    animation: snowfall 11.92136616s linear 4.75610248s infinite normal, snowflake-shake 13.27617731s ease-in-out 1.41047544s infinite alternate;
  }
  .Snowflake:nth-of-type(151) {
    top: -83.6530412px;
    left: 57.18218492vw;
    animation: snowfall 8.84906767s linear 3.46535441s infinite normal, snowflake-shake 14.62181792s ease-in-out 3.09439692s infinite alternate;
  }
  .Snowflake:nth-of-type(152) {
    top: -114.52990615px;
    left: 17.17449143vw;
    animation: snowfall 11.00092955s linear 4.34202593s infinite normal, snowflake-shake 12.43500041s ease-in-out 4.88997563s infinite alternate;
  }
  .Snowflake:nth-of-type(153) {
    top: -128.81713135px;
    left: 47.52272354vw;
    animation: snowfall 11.09336467s linear 2.06570378s infinite normal, snowflake-shake 14.97615057s ease-in-out 4.4566312s infinite alternate;
  }
  .Snowflake:nth-of-type(154) {
    top: -70.81583632px;
    left: 69.84111024vw;
    animation: snowfall 8.95800864s linear 1.0562413s infinite normal, snowflake-shake 12.45396348s ease-in-out 2.92282987s infinite alternate;
  }
  .Snowflake:nth-of-type(155) {
    top: -98.04482433px;
    left: 29.55374897vw;
    animation: snowfall 9.05502202s linear 3.4920691s infinite normal, snowflake-shake 14.54934939s ease-in-out 0.75560049s infinite alternate;
  }
  .Snowflake:nth-of-type(156) {
    top: -140.31529587px;
    left: 94.27030274vw;
    animation: snowfall 11.4508976s linear 1.79961761s infinite normal, snowflake-shake 14.22640941s ease-in-out 0.9188372s infinite alternate;
  }
  .Snowflake:nth-of-type(157) {
    top: -67.75900331px;
    left: 21.80767978vw;
    animation: snowfall 10.77886279s linear 1.42223512s infinite normal, snowflake-shake 13.36508176s ease-in-out 1.53103157s infinite alternate;
  }
  .Snowflake:nth-of-type(158) {
    top: -89.56570373px;
    left: 80.08530429vw;
    animation: snowfall 8.85793033s linear 2.8243948s infinite normal, snowflake-shake 13.11640499s ease-in-out 0.04215734s infinite alternate;
  }
  .Snowflake:nth-of-type(159) {
    top: -72.28112689px;
    left: 5.82107666vw;
    animation: snowfall 10.43963348s linear 2.96210224s infinite normal, snowflake-shake 13.15025124s ease-in-out 1.55889119s infinite alternate;
  }
  .Snowflake:nth-of-type(160) {
    top: -145.66800758px;
    left: 4.31137733vw;
    animation: snowfall 8.2784076s linear 4.64775024s infinite normal, snowflake-shake 13.26963685s ease-in-out 4.34861216s infinite alternate;
  }
  .Snowflake:nth-of-type(161) {
    top: -56.13797836px;
    left: 99.54480219vw;
    animation: snowfall 10.00459025s linear 0.84549565s infinite normal, snowflake-shake 15.19644039s ease-in-out 4.58453161s infinite alternate;
  }
  .Snowflake:nth-of-type(162) {
    top: -84.52290122px;
    left: 64.98183994vw;
    animation: snowfall 11.48183706s linear 4.17982884s infinite normal, snowflake-shake 14.48758944s ease-in-out 0.43027891s infinite alternate;
  }
  .Snowflake:nth-of-type(163) {
    top: -149.26435373px;
    left: 71.68748787vw;
    animation: snowfall 9.98651051s linear 1.53023396s infinite normal, snowflake-shake 12.16317785s ease-in-out 2.68307794s infinite alternate;
  }
  .Snowflake:nth-of-type(164) {
    top: -97.17970455px;
    left: 16.72574732vw;
    animation: snowfall 9.88509072s linear 3.89852672s infinite normal, snowflake-shake 14.06607971s ease-in-out 2.66933632s infinite alternate;
  }
  .Snowflake:nth-of-type(165) {
    top: -117.93826715px;
    left: 85.12943149vw;
    animation: snowfall 10.6216891s linear 1.89047193s infinite normal, snowflake-shake 14.63333789s ease-in-out 3.37276094s infinite alternate;
  }
  .Snowflake:nth-of-type(166) {
    top: -58.7474079px;
    left: 40.05412402vw;
    animation: snowfall 11.24540066s linear 3.74549406s infinite normal, snowflake-shake 15.94176958s ease-in-out 4.7762044s infinite alternate;
  }
  .Snowflake:nth-of-type(167) {
    top: -84.27491717px;
    left: 92.11207478vw;
    animation: snowfall 10.53437992s linear 4.21555825s infinite normal, snowflake-shake 12.26233148s ease-in-out 3.13008694s infinite alternate;
  }
  .Snowflake:nth-of-type(168) {
    top: -56.02629893px;
    left: 61.03034483vw;
    animation: snowfall 11.83131959s linear 2.19588336s infinite normal, snowflake-shake 13.11773985s ease-in-out 1.25391934s infinite alternate;
  }
  .Snowflake:nth-of-type(169) {
    top: -52.74054365px;
    left: 9.17271201vw;
    animation: snowfall 11.41260882s linear 1.92596116s infinite normal, snowflake-shake 13.79983752s ease-in-out 4.50098092s infinite alternate;
  }
  .Snowflake:nth-of-type(170) {
    top: -143.62224147px;
    left: 5.53118298vw;
    animation: snowfall 10.38170076s linear 4.3589398s infinite normal, snowflake-shake 13.91769592s ease-in-out 2.41137274s infinite alternate;
  }
  .Snowflake:nth-of-type(171) {
    top: -107.08306364px;
    left: 68.40520036vw;
    animation: snowfall 9.86339081s linear 1.94501851s infinite normal, snowflake-shake 14.81521068s ease-in-out 2.11967588s infinite alternate;
  }
  .Snowflake:nth-of-type(172) {
    top: -56.61620131px;
    left: 32.3948023vw;
    animation: snowfall 8.5839486s linear 0.96240022s infinite normal, snowflake-shake 14.02713418s ease-in-out 1.95918432s infinite alternate;
  }
  .Snowflake:nth-of-type(173) {
    top: -112.21283344px;
    left: 35.44474728vw;
    animation: snowfall 11.93357384s linear 3.18987966s infinite normal, snowflake-shake 13.68282307s ease-in-out 4.99170943s infinite alternate;
  }
  .Snowflake:nth-of-type(174) {
    top: -76.37780271px;
    left: 93.55334914vw;
    animation: snowfall 10.10992283s linear 4.79109801s infinite normal, snowflake-shake 13.23619515s ease-in-out 3.71743045s infinite alternate;
  }
  .Snowflake:nth-of-type(175) {
    top: -141.44080533px;
    left: 83.09375484vw;
    animation: snowfall 10.45479277s linear 3.52207619s infinite normal, snowflake-shake 12.13924025s ease-in-out 4.43281207s infinite alternate;
  }
  .Snowflake:nth-of-type(176) {
    top: -145.17576751px;
    left: 96.53886201vw;
    animation: snowfall 8.14131213s linear 2.0658282s infinite normal, snowflake-shake 13.54892493s ease-in-out 4.15278305s infinite alternate;
  }
  .Snowflake:nth-of-type(177) {
    top: -93.86976791px;
    left: 85.12261323vw;
    animation: snowfall 9.02540682s linear 2.72224744s infinite normal, snowflake-shake 15.47607067s ease-in-out 4.79796057s infinite alternate;
  }
  .Snowflake:nth-of-type(178) {
    top: -140.65467959px;
    left: 30.0912899vw;
    animation: snowfall 11.29870053s linear 4.84003867s infinite normal, snowflake-shake 13.14723725s ease-in-out 3.26574696s infinite alternate;
  }
  .Snowflake:nth-of-type(179) {
    top: -145.94913797px;
    left: 88.90930363vw;
    animation: snowfall 10.30023069s linear 4.79400898s infinite normal, snowflake-shake 15.65640899s ease-in-out 0.10894124s infinite alternate;
  }
  .Snowflake:nth-of-type(180) {
    top: -100.10415657px;
    left: 43.55825521vw;
    animation: snowfall 11.18599517s linear 3.74844693s infinite normal, snowflake-shake 12.12386031s ease-in-out 1.03323695s infinite alternate;
  }
  .Snowflake:nth-of-type(181) {
    top: -104.39023853px;
    left: 69.98184397vw;
    animation: snowfall 8.01004733s linear 0.38206053s infinite normal, snowflake-shake 14.32722257s ease-in-out 1.63770693s infinite alternate;
  }
  .Snowflake:nth-of-type(182) {
    top: -81.03656175px;
    left: 51.47672299vw;
    animation: snowfall 9.57405361s linear 2.96730829s infinite normal, snowflake-shake 14.39084698s ease-in-out 3.48116208s infinite alternate;
  }
  .Snowflake:nth-of-type(183) {
    top: -120.02911898px;
    left: 21.70480951vw;
    animation: snowfall 10.96630825s linear 3.65851418s infinite normal, snowflake-shake 15.1261414s ease-in-out 1.63728909s infinite alternate;
  }
  .Snowflake:nth-of-type(184) {
    top: -66.27435522px;
    left: 29.7473837vw;
    animation: snowfall 8.31637713s linear 3.50923655s infinite normal, snowflake-shake 15.09725853s ease-in-out 1.09742517s infinite alternate;
  }
  .Snowflake:nth-of-type(185) {
    top: -109.64940407px;
    left: 45.96574155vw;
    animation: snowfall 9.63081856s linear 0.17520256s infinite normal, snowflake-shake 14.29767131s ease-in-out 0.01347867s infinite alternate;
  }
  .Snowflake:nth-of-type(186) {
    top: -51.9797435px;
    left: 43.88297725vw;
    animation: snowfall 11.45500776s linear 1.06901628s infinite normal, snowflake-shake 15.92510003s ease-in-out 4.38156301s infinite alternate;
  }
  .Snowflake:nth-of-type(187) {
    top: -79.08147855px;
    left: 74.81376788vw;
    animation: snowfall 11.47071747s linear 1.42296082s infinite normal, snowflake-shake 14.23403492s ease-in-out 3.31049062s infinite alternate;
  }
  .Snowflake:nth-of-type(188) {
    top: -106.48716933px;
    left: 31.82723775vw;
    animation: snowfall 10.48630196s linear 0.08930774s infinite normal, snowflake-shake 13.62515041s ease-in-out 0.74030764s infinite alternate;
  }
  .Snowflake:nth-of-type(189) {
    top: -133.92884548px;
    left: 33.69771775vw;
    animation: snowfall 8.58198393s linear 3.5264138s infinite normal, snowflake-shake 12.92077621s ease-in-out 0.06694165s infinite alternate;
  }
  .Snowflake:nth-of-type(190) {
    top: -125.21038766px;
    left: 19.86289517vw;
    animation: snowfall 9.51878125s linear 4.74548963s infinite normal, snowflake-shake 12.62556619s ease-in-out 3.12027732s infinite alternate;
  }
  .Snowflake:nth-of-type(191) {
    top: -144.07052012px;
    left: 99.75055584vw;
    animation: snowfall 11.69107824s linear 0.63410935s infinite normal, snowflake-shake 15.21231444s ease-in-out 2.62049796s infinite alternate;
  }
  .Snowflake:nth-of-type(192) {
    top: -121.67408384px;
    left: 27.12137533vw;
    animation: snowfall 8.241678s linear 0.24434646s infinite normal, snowflake-shake 13.87780454s ease-in-out 1.22084093s infinite alternate;
  }
  .Snowflake:nth-of-type(193) {
    top: -70.67362905px;
    left: 55.1727163vw;
    animation: snowfall 8.38655341s linear 4.01838944s infinite normal, snowflake-shake 12.83668781s ease-in-out 3.98960599s infinite alternate;
  }
  .Snowflake:nth-of-type(194) {
    top: -69.67825795px;
    left: 36.66998345vw;
    animation: snowfall 10.6134217s linear 4.30533172s infinite normal, snowflake-shake 13.57258191s ease-in-out 2.95814518s infinite alternate;
  }
  .Snowflake:nth-of-type(195) {
    top: -50.92515564px;
    left: 9.3094956vw;
    animation: snowfall 9.26682962s linear 1.43170078s infinite normal, snowflake-shake 12.62990871s ease-in-out 1.30682612s infinite alternate;
  }
  .Snowflake:nth-of-type(196) {
    top: -77.93964616px;
    left: 7.9215054vw;
    animation: snowfall 11.79780442s linear 2.49694547s infinite normal, snowflake-shake 12.59618969s ease-in-out 2.09296748s infinite alternate;
  }
  .Snowflake:nth-of-type(197) {
    top: -103.47974893px;
    left: 18.39978529vw;
    animation: snowfall 8.95285507s linear 4.30989992s infinite normal, snowflake-shake 15.37977349s ease-in-out 4.65625274s infinite alternate;
  }
  .Snowflake:nth-of-type(198) {
    top: -136.5181137px;
    left: 90.66308205vw;
    animation: snowfall 11.13270009s linear 4.64906748s infinite normal, snowflake-shake 13.45080812s ease-in-out 2.39867507s infinite alternate;
  }
  .Snowflake:nth-of-type(199) {
    top: -134.81933097px;
    left: 11.81183059vw;
    animation: snowfall 9.48528155s linear 1.35473191s infinite normal, snowflake-shake 15.84300606s ease-in-out 3.39361192s infinite alternate;
  }
  .Snowflake:nth-of-type(200) {
    top: -111.99704061px;
    left: 52.33342718vw;
    animation: snowfall 8.93352454s linear 1.32339744s infinite normal, snowflake-shake 14.39970133s ease-in-out 2.63089044s infinite alternate;
  }
  @keyframes snowfall {
    to {
      top: 100vh;
    }
  }
  @keyframes snowflake-shake {
    0% {
      transform: translateX(0);
    }
    33% {
      transform: translateX(-5vh);
    }
    100% {
      transform: translateX(5vh);
    }
  }
  