.HistorySection {
    background-image: linear-gradient(to bottom, #2B286C, #4E4997);
    padding: 30px 0 40px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: white;
}

.HistoryContent {
    padding-top: 15px;
    width: 80%;
    max-width: 1120px;
    position: relative;

    /* background-color: rgba(80, 20, 20, 0.3); */
}

.TimelineElement {
    background-color: transparent;
}