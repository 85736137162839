.CarouselContainer {
    max-width: 95%;
    width: 1050px;
    padding: 0 45px;
    margin: auto;
    position: relative;
    box-sizing: border-box;
}

.PrevButton {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: rotate(180deg) translate(0%, 50%);
    
}

.NextButton {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: rotate(180);
    transform: translate(-100%, -50%);
}

.DummyItem {
    width: 320px;
    display: none;
}

@media (max-width: 1105px) {
    .CarouselContainer {
        width: 730px;
        max-width: 90%;
    }
}

@media (max-width: 829px) {
    .CarouselContainer {
        padding: 0;
        overflow: hidden;
        /* padding-left: calc(45vw - 320px); */
        height: 503px;
    }

    .CarouselWrapper {
        width: 1280px;
        margin: auto;
        position : absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .OverlayRight, .OverlayLeft {   
        position: absolute;
        top: 0;
        height: 100%;

        width: calc(45vw - 330px);
    }
    
    .OverlayLeft {
        left: 0;
        background-image: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));
    }

    .OverlayRight {
        right: 0;
        background-image: linear-gradient(to left, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));

    }

    .DummyItem {
        display: block;
    }

    .PrevButton, .NextButton {
        display: none;
    }
}

@media (max-width: 779px) {
    .CarouselContainer {
        /* width: 460px; */
        max-width: 100%;
    }

    .CarouselWrapper {
        width: 960px;
    }

    .OverlayRight, .OverlayLeft {
        position: absolute;
        top: 0;
        height: 100%;
        /* background-color: #f3f3f394; */
        width: calc(50vw - 160px);
        max-width: 205px;
        min-width: 40px;
        pointer-events: none;
    }
}

@media (max-width: 400px) {
    .CarouselWrapper {
        width: calc(3 * (95vw - 70px));
    }
}