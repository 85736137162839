.ProductBanner {
    width: 100%;
    color: white;
    padding: 75px 0 60px 0;
    position: relative;
    overflow: hidden;
}

.Content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    margin: auto;
    position: relative;
    z-index: 1;
}

.ImageContainer {
    width: 42%;
    order: 2;
    max-height: 360px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
}

.ImageContainer img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
    
}

.TropicalButterflyContainer {
    position: absolute;
    bottom: 25%;
    left: 10%;
}

.BannerText {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;
}

.BannerText a,
.BannerText a:visited,
.BannerText a:active {
    text-decoration: none;
    color: white;
}

.BannerText p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
}

.LeftImage .ImageContainer {
    order: 1;
}

.LeftImage .BannerText {
    order: 2;
    text-align: left;
    padding-left: 0;
    padding-right: 5%;
}

.EasyOpenContainer {
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translateX(-100%);
    display: flex;
}

@media (max-width: 1023px) {
    .ProductBanner {
        padding: 50px 0 40px 0;
    }
    
    .Content {
        width: 90%;
    }

    .ImageContainer {
        max-width: 360px;
    }

    .BannerText {
        width: 52%;
        padding: 0;
    }

    .LeftImage .BannerText {
        padding: 0;
    }
}

@media (max-width: 550px) {
    .ProductBanner {
        padding: 40px 0 30px 0;
    }

    .EasyOpenContainer {
        display: none;
    }

    .Content {
        flex-flow: column;
    }

    .ImageContainer {
        order: 1;
        width: 60%;
    }

    .BannerText {
        text-align: center;
        order: 2;
        width: 90%;
    }

    .BannerText h2 {
        font-size: 35px;
    }

    .LeftImage .BannerText {
        text-align: center;
    }
}