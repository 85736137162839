.HomePage {
    width: 100%;
    background-color: white;
}

.DummyContainer {
    width: 100%;
    height: 500px;
    position: relative;


    border: 1px solid green;
    background-color: rgba(0, 255, 0, 0.2);
}

h2.Placeholder {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 60px;
        letter-spacing: 10px;
}