.FormContainer {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 25px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    z-index: 10;
}

.FormContainer form {
    width: 100%;
    margin-bottom: 12px;
}

.FormContainer h2, 
.FormContainer h3 {
    color: #3F3C76;
}

.FormContainer form {
    padding-top: 5px;
}

.ContactInfoContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
}

.SubmitButton {
    background-color: #EF4C32;
    color: #f3f3f3;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 11px 22px;
    margin: 10px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    min-width: 225px;
    border: 2px solid #EF4C32;
    letter-spacing: 1px;
    position: relative;
}

.SubmitButton img {
    width: auto;
    position: absolute;
    top: 50%;
    left: 90%;

    transform: translate(-50%, -50%);
}

@media (max-width: 1023px) {
    .FormContainer {
        width: 90%;
    }
}

@media (max-width: 767px) {
    .FormContainer {
        margin-top: 10px;
    }

    .ContactInfoContainer {
        flex-flow: column;
    }
}