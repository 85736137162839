.SeeMoreButton {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #F0533A, #FC7441);
    border-radius: 50%;
    display: flex;
}

.SeeMoreButtonContent {
    margin: auto;
    color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 10px;
}

.SeeMoreButtonContent p {
    font-size: 16px;
    margin-bottom: 2px;
    margin: 0;
}

.SeeMoreButtonContent h3 {
    margin-top: 0;
}