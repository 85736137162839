.ProductNavBar {
    height: 70px;
    width: 100%;
    padding: 0 14%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 1;
    position: relative;
}

.BackLink {
    height: 70px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: #4C4A80;
    cursor: pointer;
}

.BackLink img {
    margin-right: 15px;
}

.BreadCrumbs {
    color: #888;
    font-size: 14px;
}

.BreadCrumbDivider {
    white-space: pre;
}

.BreadCrumbs a,
.BreadCrumbs a:visited,
.BreadCrumbs a:active {
    text-decoration: none;
    color: #888;
    display: inline-block;
    
}

.CurrentPageBreadCrumb {
    display: inline-block;
}

.CurrentPageBreadCrumb a,
.CurrentPageBreadCrumb a:visited,
.CurrentPageBreadCrumb a:active {
    text-decoration: none;
    color: #4C4A80;
    font-weight: 500;
}

.ContactUsLink {
    color: #888;
    font-size: 14px;
}

.ContactUsLink img {
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .ProductNavBar {
        padding: 0 2%;
    }
}

@media (max-width: 1023px) {
    .ProductNavBar {
        padding: 0 6%;
    }

    .BreadCrumbs {
        display: none;
    }
}

@media (max-width: 480px) {
    .ContactUsLink {
        display: none;
    }
}