.MenuIcon {
    width: 30px;
    height: 29px;
    display: inline-block;
    cursor: pointer;
    margin: auto;
}


.Bar1, .Bar2, .Bar3 {
    width: 30px;
    height: 3px;
    border-radius: 3px;
    background-color: #eee;
    margin: 5px 0;
    transition: 0.4s;
}

.Change .Bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.Change .Bar2 {opacity: 0;}

.Change .Bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}