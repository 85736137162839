.ProductBanner {
    width: 100%;
    color: white;
    padding: 75px 0 60px 0;
    position: relative;
    overflow: hidden;
}

.Content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: auto;
}

.ImageContainer {
    width: 42%;
    order: 2;
    max-height: 360px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.ImageContainer img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
    animation: ship 7s ease-in-out infinite;
    /* animation-delay: -6s; */

}

.BannerText {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;

    z-index: 2;
}

.BannerText a,
.BannerText a:visited,
.BannerText a:active {
    text-decoration: none;
    color: white;
}

.BannerText p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
}

.LeftImage .ImageContainer {
    order: 1;
}

.LeftImage .BannerText {
    order: 2;
    text-align: left;
    padding-left: 0;
    padding-right: 5%;
}

.EasyOpenContainer {
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translateX(-100%);
    display: flex;
}


/*
Wave animation
*/

.Ocean { 
    height: 5%;
    width:100%;
    position:absolute;
    bottom:-5%;
    left:0;
    background: #015871;
    transform: scaleY(0.9);
    z-index: 1;
  }
  
  .Wave {
    /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;  */
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }
  
  .Wave:nth-of-type(2) {
    top: -182px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @keyframes wave {
    0% {
      margin-left: calc(50% - 2690px);
    }
    100% {
      margin-left: calc(50% - 4290px);
    }
  }
  
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-5px,0);
    }
    50% {
      transform: translate3d(0,25px,0);
    }
  }

/*
Ship Animation
*/

@keyframes ship {
    0%, 100% {
        transform: translateY(-55px);
    }
    50% {
        transform: translateY(25px);
    }
}

@media (max-width: 1023px) {
    .ProductBanner {
        padding: 50px 0 40px 0;
    }
    
    /* .Content {
        width: 90%;
    } */

    .ImageContainer {
        max-width: 360px;
        transform: translateY(10px);
    }

    .BannerText {
        width: 52%;
        padding: 0;
    }

    .LeftImage .BannerText {
        padding: 0;
    }

    .Ocean {
        transform: scale(0.8);
        bottom: -10%;
    }

    @keyframes ship {
        0%, 100% {
            transform: translateY(-50px);
        }
        50% {
            transform: translateY(15px);
        }
    }
}

@media (max-width: 767px) {
    .Ocean {
        display: none;
    }
    
    .ImageContainer img {
        animation: none;
    }
}

@media (max-width: 550px) {
    .ProductBanner {
        padding: 40px 0 30px 0;
    }

    .EasyOpenContainer {
        display: none;
    }

    .Content {
        flex-flow: column;
    }

    .ImageContainer {
        order: 1;
        width: 60%;
    }

    .BannerText {
        text-align: center;
        order: 2;
        width: 90%;
    }

    .BannerText h2 {
        font-size: 35px;
    }

    .LeftImage .BannerText {
        text-align: center;
    }
}