.Footer {
    width: 100%;
    padding-top: 50px;
    display: block;
    flex-flow: column;
    align-items: center;
    text-align: center;
    position: relative;
    font-size: 14px;
}

.LogoContainer {
    margin-bottom: 30px;
}

.LinksContainer {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.LinksContainer a {
    margin: 0 10px;
    text-decoration: none;
    color: #555;
}

.TrademarkContainer {
    margin: 30px 0;
    color: #999;
}

.SoftZenLink {
    color: #555;
    margin: 30px 0;
    position: absolute;
    top: 80%;
    left: 5%;
    transform: translateY(-50%);
    font-size: 15px;
}

.SoftZenLink a,
.SoftZenLink a:active,
.SoftZenLink a:visited {
    text-decoration: none;
    color: rgb(86, 129, 248);
}

.BackToTop {
    position: absolute;
    top: 80%;
    left: 95%;
    
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    .Footer {
        padding-bottom: 70px;
    }

    .BackToTop {
        bottom: 5%;
        top: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .SoftZenLink {
        position: static;
    }
}