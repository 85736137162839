/* 
Green: #70B258
Blue: #41B3E4
Orange: #FF672E
Violet: #4E4997
Dark Violet: #2B286C
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Fira sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1200px; */
  background-color: #f8f8f8;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

body img {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-family: 'Lobster';
  font-size: 2.625em;
  margin-top: 0.4762em;
  margin-bottom: 0.5952em;
  font-weight: 400;
}

h3 {
  font-family: 'Lobster';
  font-size: 1.625em;
  margin-top: 0.7692em;
  margin-bottom: 0.7692em;
  font-weight: normal;
  font-weight: initial;
}

@media(max-width: 1023px) {
  body, html {
    font-size: 15px;
  }
}

/* @media(max-width: 767px) {
  body, html {
    font-size: 14px;
  }
} */
.Footer_Footer__2SgP1 {
    width: 100%;
    padding-top: 50px;
    display: block;
    flex-flow: column;
    align-items: center;
    text-align: center;
    position: relative;
    font-size: 14px;
}

.Footer_LogoContainer__1-an2 {
    margin-bottom: 30px;
}

.Footer_LinksContainer__gaJ7V {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Footer_LinksContainer__gaJ7V a {
    margin: 0 10px;
    text-decoration: none;
    color: #555;
}

.Footer_TrademarkContainer__1JEcK {
    margin: 30px 0;
    color: #999;
}

.Footer_SoftZenLink__2FuLA {
    color: #555;
    margin: 30px 0;
    position: absolute;
    top: 80%;
    left: 5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 15px;
}

.Footer_SoftZenLink__2FuLA a,
.Footer_SoftZenLink__2FuLA a:active,
.Footer_SoftZenLink__2FuLA a:visited {
    text-decoration: none;
    color: rgb(86, 129, 248);
}

.Footer_BackToTop__lLfRT {
    position: absolute;
    top: 80%;
    left: 95%;
    
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    .Footer_Footer__2SgP1 {
        padding-bottom: 70px;
    }

    .Footer_BackToTop__lLfRT {
        bottom: 5%;
        top: auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .Footer_SoftZenLink__2FuLA {
        position: static;
    }
}
.LanguageSelect_LanguageSelect__1X5Rh {
    background-color: #404072;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    position: relative;    
    font-size: 13px;
    font-weight: 500;
}

.LanguageSelect_SelectedContainer__1K6gT {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.LanguageSelect_FlagContainer__2ypRu {
    margin-right: 10px;
}

.LanguageSelect_ArrowDown__19Ric {
    width: 20%;
    color: #898AA8;
}

.LanguageSelect_CountryList__J7eGQ {
    position: absolute;
    top: 100%;
    background-color: white;
    color: white;
    width: 100%;
    border: 1px groove #403C77;
    border-radius: 3px;
    background-color: #403C77;
}

.LanguageSelect_Option__30kx7 {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 80%;
    padding: 10px 0 10px 17px;
    cursor: pointer;
}

.LanguageSelect_CountryList__J7eGQ .LanguageSelect_Option__30kx7 {
    width: 100%;
}

.LanguageSelect_CountryList__J7eGQ .LanguageSelect_Option__30kx7:hover {
    background-color: #3B3867;
}

@media (max-width: 767px) {
    .LanguageSelect_DesktopAndTablet__IwlUN {
        display: none;
    }

    .LanguageSelect_LanguageSelect__1X5Rh {
        font-weight: 400;
    }

    .LanguageSelect_FlagContainer__2ypRu {
        margin: auto;
    }

    .LanguageSelect_Selected__2NF12 {
        width: 100%;
        padding: 0;
        font-size: 16px;
    }

    .LanguageSelect_CountryList__J7eGQ {
        width: 100vw;
        height: calc(83vh - 60px);
        background-color: #403C77;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-radius: 0;
        color: white;
        padding-top: 15px;
    }

    .LanguageSelect_CountryList__J7eGQ .LanguageSelect_Option__30kx7 {
        justify-content: center;
        font-size: 21px;

    }

    .LanguageSelect_CountryList__J7eGQ .LanguageSelect_Option__30kx7 .LanguageSelect_FlagContainer__2ypRu {
        margin: 0;
        margin-right: 10px;
        font-size: 16px;
    }

    .LanguageSelect_CountryList__J7eGQ .LanguageSelect_Option__30kx7:hover {
        background-color: rgba(42, 42, 42, 0.2);
    }
}

/* @media (min-width: 768px) {
    .MobileOnly {
        display: none;
    }
} */

.NavigationItem_NavigationItem__2-Ddx {
    /* margin: 0 4%; */

    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
    text-align: center;
}

.NavigationItem_NavigationItem__2-Ddx a {
    color: #f5f5f5;
    text-decoration: none;
    width: 100%;
    padding: 8px 5px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    display: block;
    height: 100%;
    padding: 16px 10px;
    font-size: 17px;
}

.NavigationItem_NavigationItem__2-Ddx a:hover,
.NavigationItem_NavigationItem__2-Ddx a:active,
.NavigationItem_NavigationItem__2-Ddx a.NavigationItem_active__2rAoj {
    color: white;
    border-top: 3px solid rgb(255, 113, 19);
}

@media (max-width: 767px) {
    .NavigationItem_NavigationItem__2-Ddx {
        padding: 0;
        margin-bottom: 17px;
    }

    .NavigationItem_NavigationItem__2-Ddx a {
        padding: 0 8px;
        font-size: 21px;
    }

    .NavigationItem_NavigationItem__2-Ddx a:hover,
    .NavigationItem_NavigationItem__2-Ddx a:active,
    .NavigationItem_NavigationItem__2-Ddx a.NavigationItem_active__2rAoj {
        color: white;
        border-top: 3px solid transparent;
        border-bottom: 3px solid rgb(255, 113, 19);
    }
}

@media screen and (max-height: 450px) {
    .NavigationItem_NavigationItem__2-Ddx {
        margin-bottom: 12px;
    }    

    .NavigationItem_NavigationItem__2-Ddx a {
        font-size: 18px;
    }
}
.NavigationItems_NavigationItems__1SaV_ {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: auto;
    
}

@media (max-width: 767px) {
    .NavigationItems_NavigationItems__1SaV_ {
        flex-flow: column;
        justify-content: flex-start;
    }
}
.MenuIcon_MenuIcon__8kJuS {
    width: 30px;
    height: 29px;
    display: inline-block;
    cursor: pointer;
    margin: auto;
}


.MenuIcon_Bar1__3zcQk, .MenuIcon_Bar2__2M_Si, .MenuIcon_Bar3__1RrmB {
    width: 30px;
    height: 3px;
    border-radius: 3px;
    background-color: #eee;
    margin: 5px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.MenuIcon_Change__1LIL2 .MenuIcon_Bar1__3zcQk {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.MenuIcon_Change__1LIL2 .MenuIcon_Bar2__2M_Si {opacity: 0;}

.MenuIcon_Change__1LIL2 .MenuIcon_Bar3__1RrmB {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}
.MobileMenu_Overlay__17SYR {
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-image: -webkit-linear-gradient(top, #2B286C, #5550A4);
    background-image: linear-gradient(to bottom, #2B286C, #5550A4);
    overflow-y: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    height: 83%;
}

.MobileMenu_OverlayContent__1pqIy {
    position: relative;
    top: 60px;
    width: 100%;
    margin-top: 25px;
}

/* .Overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.Overlay a:hover, .Overlay a:focus {
    color: #f1f1f1;
} */

.MobileMenu_Overlay__17SYR .MobileMenu_CloseBtnContainer__2O72o {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    line-height: 45px;
    cursor: pointer;
}

.MobileMenu_Logo__1MM7S {
    position: absolute;
    top: 0;
    left: 50%;

    height: 60px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    -webkit-transform: translateX(-50%);

            transform: translateX(-50%);
}

.MobileMenu_Logo__1MM7S a {
    padding: 0;
    font-size: medium;
    font-size: initial;
}

.MobileMenu_Logo__1MM7S img {
    margin: auto;
    max-height: 48px;
}


@media screen and (max-height: 450px) {
  .MobileMenu_Overlay__17SYR a {font-size: 16px}
  .MobileMenu_Overlay__17SYR .MobileMenu_CloseBtn__2P3gZ {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (min-width: 768px) {
    .MobileMenu_Overlay__17SYR {
        display: none;
    }
}
.Toolbar_Toolbar__SaP0y {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2B286C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14%;
    box-sizing: border-box;
    color: white;
    position: relative;
    z-index: 100;
}

.Toolbar_Logo__37gbW {
    order: 1;
    max-height: 80%;
}

.Toolbar_Logo__37gbW img {
    max-height: 48px;
}

.Toolbar_Toolbar__SaP0y nav {
    height: 100%;
    width: 50%;
    max-width: 420px;
    min-width: 360px;

    order: 2;
}

.Toolbar_LanguageSelectContainer__2xyK0 {
    order: 3;

    width: 150px;
    height: 66%;
}

.Toolbar_MenuIconContainer__1ygD8 {
    order: 3;

    display: flex;
    width: 60px;
    height: 60px;
}

@media(max-width: 75em) {
    .Toolbar_Toolbar__SaP0y {
        padding: 0 2%;
    }
}

@media(max-width: 767px) {
    .Toolbar_Toolbar__SaP0y {
        padding: 0;
    }
    
    .Toolbar_DesktopAndTablet__1ffOY {
        display: none;
    }

    .Toolbar_LanguageSelectContainer__2xyK0 {
        order: 1;
        height: 60px;
        width: 60px;
    }

    .Toolbar_Logo__37gbW {
        order: 2;
    }
}

@media (min-width: 768px) {
    .Toolbar_MobileOnly__2aKJQ {
        display: none;
    }
}

.CloudBackground_CloudBackground__1yZwl {
    width: 100%;
    padding: 0;
    position: relative;
    background-image: -webkit-linear-gradient(top, #5ECBEE, #E3F8FF 90%);
    background-image: linear-gradient(to bottom, #5ECBEE, #E3F8FF 90%);
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: hidden;
}


.CloudBackground_CloudTopLeft__11C21 {
    position: absolute;
    left: 0%;
    top: 0%;

    -webkit-transform: translate(-50%, -20%);

            transform: translate(-50%, -20%);
    /* clip-path: polygon(0 20%, 100% 20%, 100% 100%, 0 100%); */
    -webkit-animation: CloudBackground_topLeftAnimation__2ZehV 24s infinite ease-in-out;
            animation: CloudBackground_topLeftAnimation__2ZehV 24s infinite ease-in-out;
}

.CloudBackground_CloudTopRight__1pru8 {
    /* width: 18%; */

    position: absolute;
    left: 100%;
    top: 0%;

    -webkit-transform: translate(-50%, -70%);

            transform: translate(-50%, -70%);
    /* clip-path: polygon(0 40%, 100% 40%, 100% 100%, 0 100%); */
    -webkit-animation: CloudBackground_topRightAnimation__3cHsD 24s infinite ease-in-out;
            animation: CloudBackground_topRightAnimation__3cHsD 24s infinite ease-in-out;
}

.CloudBackground_CloudBottomLeft__3O0GL {

    position: absolute;
    left: 0%;
    top: 100%;

    -webkit-transform: translate(-75%, -75%);

            transform: translate(-75%, -75%);
    /* clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%); */
    -webkit-animation: CloudBackground_bottomLeftAnimation__unS3v 24s infinite ease-in-out;
            animation: CloudBackground_bottomLeftAnimation__unS3v 24s infinite ease-in-out;
}

.CloudBackground_CloudBottomRight__1E71e {
    width: 42%;

    position: absolute;
    left: 100%;
    top: 100%;

    -webkit-transform: translate(-100%, -90%);

            transform: translate(-100%, -90%);
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0 90%); */

    -webkit-animation: CloudBackground_bottomRightAnimation__23hyY 24s infinite ease-in-out;

            animation: CloudBackground_bottomRightAnimation__23hyY 24s infinite ease-in-out;

}

@-webkit-keyframes CloudBackground_topLeftAnimation__2ZehV {
    0% {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%);
    }

    50% {
        -webkit-transform: translate(calc(-50% + 500px), -20%);
                transform: translate(calc(-50% + 500px), -20%);
    }

    100% {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%);
    }
}

@keyframes CloudBackground_topLeftAnimation__2ZehV {
    0% {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%);
    }

    50% {
        -webkit-transform: translate(calc(-50% + 500px), -20%);
                transform: translate(calc(-50% + 500px), -20%);
    }

    100% {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%);
    }
}

@-webkit-keyframes CloudBackground_topRightAnimation__3cHsD {
    0% {
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
    }

    50% {
        -webkit-transform: translate(calc(-50% + 240px), -70%);
                transform: translate(calc(-50% + 240px), -70%);
    }

    100% {
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
    }
}

@keyframes CloudBackground_topRightAnimation__3cHsD {
    0% {
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
    }

    50% {
        -webkit-transform: translate(calc(-50% + 240px), -70%);
                transform: translate(calc(-50% + 240px), -70%);
    }

    100% {
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
    }
}

@-webkit-keyframes CloudBackground_bottomLeftAnimation__unS3v {
    0% {
        -webkit-transform: translate(-75%, -75%);
                transform: translate(-75%, -75%);
    }

    50% {
        -webkit-transform: translate(calc(-75% + 375px), -75%);
                transform: translate(calc(-75% + 375px), -75%);
    }

    100% {
        -webkit-transform: translate(-75%, -75%);
                transform: translate(-75%, -75%);
    }
}

@keyframes CloudBackground_bottomLeftAnimation__unS3v {
    0% {
        -webkit-transform: translate(-75%, -75%);
                transform: translate(-75%, -75%);
    }

    50% {
        -webkit-transform: translate(calc(-75% + 375px), -75%);
                transform: translate(calc(-75% + 375px), -75%);
    }

    100% {
        -webkit-transform: translate(-75%, -75%);
                transform: translate(-75%, -75%);
    }
}

@-webkit-keyframes CloudBackground_bottomRightAnimation__23hyY {
    0% {
        -webkit-transform: translate(-100%, -90%);
                transform: translate(-100%, -90%);
    }

    50% {
        -webkit-transform: translate(calc(-100% + 200px), -90%);
                transform: translate(calc(-100% + 200px), -90%);
    }

    100% {
        -webkit-transform: translate(-100%, -90%);
                transform: translate(-100%, -90%);
    }
}

@keyframes CloudBackground_bottomRightAnimation__23hyY {
    0% {
        -webkit-transform: translate(-100%, -90%);
                transform: translate(-100%, -90%);
    }

    50% {
        -webkit-transform: translate(calc(-100% + 200px), -90%);
                transform: translate(calc(-100% + 200px), -90%);
    }

    100% {
        -webkit-transform: translate(-100%, -90%);
                transform: translate(-100%, -90%);
    }
}

@media (max-width: 767px) {
    .CloudBackground_CloudTopLeft__11C21, .CloudBackground_CloudTopRight__1pru8, .CloudBackground_CloudBottomLeft__3O0GL, .CloudBackground_CloudBottomRight__1E71e {
        -webkit-animation: none;
                animation: none;
    }
}
.VideoThumbnail_VideoThumbnail__3_TO1 {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.VideoThumbnail_Background__3_nI4 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
    width: auto;
    height: auto;
}

.VideoThumbnail_ThumbnailContent__3N-ce {
    width: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #2B286C;
    text-align: center;
}

.VideoThumbnail_PlayVideoContainer__2D93G {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.VideoThumbnail_PlayVideoContainer__2D93G p {
    display: inline-block;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 4px;
    font-size: 15px;
}

.VideoThumbnail_PlayButton__5DmFP {
    margin: 0 20px;
    cursor: pointer;
}

@media(max-width: 480px) {
    .VideoThumbnail_VideoThumbnail__3_TO1 {
        overflow: hidden;
    }

    .VideoThumbnail_PlayButton__5DmFP {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }

    .VideoThumbnail_NewLine__BSYyF {
        display: block;
    }
}
.SeeMoreButton_SeeMoreButton__2yXZ2 {
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, #F0533A, #FC7441);
    background-image: linear-gradient(to bottom, #F0533A, #FC7441);
    border-radius: 50%;
    display: flex;
}

.SeeMoreButton_SeeMoreButtonContent__cYZRK {
    margin: auto;
    color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 10px;
}

.SeeMoreButton_SeeMoreButtonContent__cYZRK p {
    font-size: 16px;
    margin-bottom: 2px;
    margin: 0;
}

.SeeMoreButton_SeeMoreButtonContent__cYZRK h3 {
    margin-top: 0;
}
.DotNavigation_DotNavigation__3WTEu {
    height: 10px;
}

.DotNavigation_Dot__UG15j {
    margin: 0px 5px;
}

.DotNavigation_Clickable__2poW9 {
    cursor: pointer;
}

.DotNavigation_CustomDot__26v66 {
    border-radius: 50%;
    display: inline-block;
}
.VideoSection_VideoBox__134Bm {
    max-width: 780px;
    max-height: 440px;
    width: 100vw;
    height: 56.4vw;
    margin: 75px auto 95px;

    position: relative;
    z-index: 5;
    box-sizing: border-box;
}

.VideoSection_SeeMoreButtonContainer__hs3f1 {
    position: absolute;
    top: 100%;
    left: 100%;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    -webkit-transform: translate(-52%, -66%);
            transform: translate(-52%, -66%);
    z-index: 20;
}

.VideoSection_DotNavigationContainer__1OF4h {
    position: relative;
    margin-top: -50px; 
    margin-bottom: 50px;
    z-index: 1;
}

@media (max-width: 1023px) {
    .VideoSection_DesktopOnly__3mrbR {
        display: none;
    }
}

@media (max-width: 780px) {
    .VideoSection_VideoBox__134Bm {
        margin: 0;
    }

    .VideoSection_DotNavigationContainer__1OF4h {
        display: none;
    }
}

@media (max-width: 480px) {
    .VideoSection_VideoBox__134Bm {
        height: 80vw;
    }
}
.AboutUsText_AboutUsText__WRmbf {
    display: block;
    color: white;
    text-align: center;
    width: 80%;
    max-width: 768px;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
}

.AboutUsText_AboutUsText__WRmbf p {
    line-height: 160%;
    font-size: 16px;
    margin: 0;
}


.Button_Button__2YU7n {
    background-color: transparent;
    border: none;
    color: #f3f3f3;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 13px 22px;
    margin: 10px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    min-width: 225px;
    border: 2px solid;
    border-color: #f3f3f3cc;
    letter-spacing: 1px;
    font-family: 'Fira sans';
}



.AboutUsSection_AboutUsSection__2RMU8 {
    background-image: -webkit-linear-gradient(top, #2B286C, #4E4997);
    background-image: linear-gradient(to bottom, #2B286C, #4E4997);
    position: relative;
}

.AboutUsSection_ButtonArea__2iyDM {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.AboutUsSection_AboutUsButton__1gsI7 {
    order: 1;
}

.AboutUsSection_ImageContainer__1XHlm {
    width: 450px;
    order: 2;
    /* background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%); */
}

.AboutUsSection_ImageContainer__1XHlm img {
    max-width: 360px;
    height: auto;
    display: block;
    margin: 0 auto;
    background-image: -webkit-radial-gradient(50% 100%, circle, #789FC5 5%, transparent 60%);
    background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%);
}

.AboutUsSection_MalivaHeroesButton__2TBtV {
    order: 3;
}

.AboutUsSection_EasyOpenContainer__11cx1 {
    position: absolute;
    top: 0%;
    left: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: flex;
}

/* .DeactivateParallax {
    position: absolute;
    top: 120%;
    width: 100%;
    height: 10px;
} */


@media (max-width: 1023px) {
    .AboutUsSection_ButtonArea__2iyDM {
        flex-wrap: wrap;
    }

    .AboutUsSection_ImageContainer__1XHlm {
        order: 3;
        width: 100%;
        margin-top: 20px;
    }

    .AboutUsSection_ImageContainer__1XHlm img {
        max-width: 300px;
    }

    .AboutUsSection_MalivaHeroesButton__2TBtV {
        order: 2;
    }
}

@media (max-width: 480px) {
    .AboutUsSection_ImageContainer__1XHlm img {
        max-width: 240px;
    }

    .AboutUsSection_EasyOpenContainer__11cx1 {
        display: none;
    }
}
.ContactUsText_ContactUsText__2OGk9 {
    display: block;
    color: white;
    /* width: 80%;
    max-width: 768px;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px; */
}

.ContactUsText_ContactUsText__2OGk9 p {
    line-height: 160%;
    margin: 0;
}


.ContactUsSection_ContactUs__1Y_WX {
    background-color: #2B286C;
    padding: 0 8%;
    padding-top: 30px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.ContactUsSection_ContactUs__1Y_WX a {
    text-decoration: none;
}

.ContactUsSection_ImageBox__idNli {
    order: 1;
    margin-top: 40px;
    background-image: -webkit-radial-gradient(50% 100%, circle, #789FC5 5%, transparent 60%);
    background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%);
}

.ContactUsSection_ImageBox__idNli img {
    display: block;
    max-width: 402px;
    height: auto;
    margin: auto;
}

.ContactUsSection_ContentBox__3nKsu {
    color: white;
    text-align: left;
    padding-left: 10%;
    padding-bottom: 40px;
    max-width: 700px;
    order: 2;
}

.ContactUsSection_ContentBox__3nKsu p {
    line-height: 160%;
    /* font-size: 16px; */
    margin: 0;
    font-family: 'Fira sans';
}

.ContactUsSection_ButtonsBox__NCxlE {
    margin-right: -20%;
}

@media( min-width: 1301px ) {
    .ContactUsSection_ContactUs__1Y_WX {
        padding: 0 12%;
    }
}

@media ( min-width: 1024px) {
    .ContactUsSection_MobileOnly__2NTNl {
        display: none;
    }
}

@media ( max-width: 1023px) {
    .ContactUsSection_DesktopOnly__3C-vG {
        display: none;
    }

    .ContactUsSection_ContactUs__1Y_WX {
        background-color: #2B286C;
        background-image: none;
        flex-flow: column;
        align-items: center;
    }

    .ContactUsSection_ContentBox__3nKsu {
        text-align: center;
        order: 1;
        padding-left: 0;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .ContactUsSection_ButtonsBox__NCxlE {
        margin: auto;
    }

    .ContactUsSection_ImageBox__idNli {
        order: 2;
        width: 80%;
        margin-top: 0;
    }

    .ContactUsSection_ImageBox__idNli img {
        width: 100%;
        max-width: 300px;
        height: auto;
    }
}

@media (max-width: 767px) {
    .ContactUsSection_ImageBox__idNli {
        display: none;
    }
}
.Spinner_Skcubegrid__3u1UB {
    width: 40px;
    height: 40px;
    margin: 100px auto;
  }
  
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube__1g7S2 {
    width: 33%;
    height: 33%;
    background-color: #521751;
    float: left;
    -webkit-animation: Spinner_Sk-cubeGridScaleDelay__25Q4k 1.3s infinite ease-in-out;
            animation: Spinner_Sk-cubeGridScaleDelay__25Q4k 1.3s infinite ease-in-out; 
  }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube1__2_txl {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube2__s7QVS {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube3__2AWaA {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube4__10dXj {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube5__GHUdc {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube6__PGBrv {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube7__3PT7G {
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube8__KhhMj {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .Spinner_Skcubegrid__3u1UB .Spinner_Skcube9__2QdLc {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  
  @-webkit-keyframes Spinner_Sk-cubeGridScaleDelay__25Q4k {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1); 
    }
  }
  
  @keyframes Spinner_Sk-cubeGridScaleDelay__25Q4k {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    } 
  }
.Tab_Tab__35fkE {
    height: 70px;
    margin: 0 40px;
    display: flex;
    align-items: center;
    color: #2B286C;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    cursor: pointer;
}

.Tab_Pointer__2u3Fw {
    position: absolute;
    width: 120%;
    height: 10px;
    background-color: red;
    top:100%;
    left: -10%;
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);

    -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 54% 50%, 50% 100%, 46% 50%, 0 50%);

            clip-path: polygon(0 0, 100% 0, 100% 50%, 54% 50%, 50% 100%, 46% 50%, 0 50%);

    background-image: -webkit-linear-gradient(left, #FE6C35, #F0533A);

    background-image: linear-gradient(to right, #FE6C35, #F0533A);
}

@media (max-width: 767px) {
    .Tab_Tab__35fkE {
        margin: 0 15px;
    }

    .Tab_Pointer__2u3Fw {
        width: 100%;
        left: 0;
    }
}
.TabsBar_TabsBar__3CuZc {
    height: 90px;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    z-index: 1;
    position: relative;
    max-width: 100%;
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;   /* IE 10+ */
}

.TabsBar_TabsBar__3CuZc::-webkit-scrollbar {
    display:none;
}

.TabsBar_TabsContainer__2eWUa {
    width: 100%;
    min-width: 450px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    box-shadow: 0 10px 20px -10px #aaa;
    position: relative;
}

.TabsBar_ContactUsLink__hXAi0 {
    position: absolute;
    top: 40%;
    left: 80%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #888;
    font-size: 14px;
}

.TabsBar_ContactUsLink__hXAi0 img {
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .TabsBar_ContactUsLink__hXAi0 {
        display: none;
    }
}
.CarouselItem_Item__3gse_ {
    width: calc(95vw - 90px);
    max-width: 300px;
    min-height: 446px;
    background-color: white;
    text-align: center;
    padding: 40px 20px 0 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px #ccc;
    cursor: pointer;
}

.CarouselItem_Item__3gse_ img {
    max-width: 100%;
    height: 187px;
}

.CarouselItem_Item__3gse_ h3 {
    margin-bottom: 15px;
}

.CarouselItem_Item__3gse_ p {
    line-height: 20px;
    color: #666;
    font-size: 14px;
}

.CarouselItem_Item__3gse_ a {
    text-decoration: none;
}

.CarouselItem_Link__1HcVA {
    margin: 30px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
}

@media (max-width: 767px) {
    .CarouselItem_Item__3gse_ {
        box-shadow: none;
    }
}
.ProductCarousel_CarouselContainer__3VL8T {
    max-width: 95%;
    width: 1050px;
    padding: 0 45px;
    margin: auto;
    position: relative;
    box-sizing: border-box;
}

.ProductCarousel_PrevButton__1YBLI {
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: rotate(180deg) translate(0%, 50%);
            transform: rotate(180deg) translate(0%, 50%);
    
}

.ProductCarousel_NextButton__CAuNB {
    position: absolute;
    left: 100%;
    top: 50%;
    -webkit-transform: rotate(180);
            transform: rotate(180);
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
}

.ProductCarousel_DummyItem__or4Gn {
    width: 320px;
    display: none;
}

@media (max-width: 1105px) {
    .ProductCarousel_CarouselContainer__3VL8T {
        width: 730px;
        max-width: 90%;
    }
}

@media (max-width: 829px) {
    .ProductCarousel_CarouselContainer__3VL8T {
        padding: 0;
        overflow: hidden;
        /* padding-left: calc(45vw - 320px); */
        height: 503px;
    }

    .ProductCarousel_CarouselWrapper__efj0W {
        width: 1280px;
        margin: auto;
        position : absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .ProductCarousel_OverlayRight__ljfXH, .ProductCarousel_OverlayLeft__3koPf {   
        position: absolute;
        top: 0;
        height: 100%;

        width: calc(45vw - 330px);
    }
    
    .ProductCarousel_OverlayLeft__3koPf {
        left: 0;
        background-image: -webkit-linear-gradient(left, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));
        background-image: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));
    }

    .ProductCarousel_OverlayRight__ljfXH {
        right: 0;
        background-image: -webkit-linear-gradient(right, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));
        background-image: linear-gradient(to left, rgba(243, 243, 243, 1), rgba(243, 243, 243, 0));

    }

    .ProductCarousel_DummyItem__or4Gn {
        display: block;
    }

    .ProductCarousel_PrevButton__1YBLI, .ProductCarousel_NextButton__CAuNB {
        display: none;
    }
}

@media (max-width: 779px) {
    .ProductCarousel_CarouselContainer__3VL8T {
        /* width: 460px; */
        max-width: 100%;
    }

    .ProductCarousel_CarouselWrapper__efj0W {
        width: 960px;
    }

    .ProductCarousel_OverlayRight__ljfXH, .ProductCarousel_OverlayLeft__3koPf {
        position: absolute;
        top: 0;
        height: 100%;
        /* background-color: #f3f3f394; */
        width: calc(50vw - 160px);
        max-width: 205px;
        min-width: 40px;
        pointer-events: none;
    }
}

@media (max-width: 400px) {
    .ProductCarousel_CarouselWrapper__efj0W {
        width: calc(3 * (95vw - 70px));
    }
}
.ProductSection_ProductSection__3kr-Q {
    width: 100%;
    margin-top: 30px;
    /* background-color: white; */
    padding-bottom: 20px;
}

.HomePage_HomePage__29MH5 {
    width: 100%;
    background-color: white;
}

.HomePage_DummyContainer__24AOS {
    width: 100%;
    height: 500px;
    position: relative;


    border: 1px solid green;
    background-color: rgba(0, 255, 0, 0.2);
}

h2.HomePage_Placeholder__3MFxt {
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        font-size: 60px;
        letter-spacing: 10px;
}
.AboutUsSection_AboutUsSection___w80m {
    background-image: -webkit-linear-gradient(left, #2B286C, #4E4997);
    background-image: linear-gradient(to right, #2B286C, #4E4997);
    position: relative;
    padding-bottom: 75px;
}

.AboutUsSection_ButtonArea__3rkLt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}



.ProductSlide_Slide__1mX1G {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
}

.ProductSlide_ImageContainer__1E1xI {
    width: 38%;
    order: 2;
    max-height: 240px;
}

.ProductSlide_ImageContainer__1E1xI img {
    max-height: 240px;
    max-width: 100%;
    height: auto;
    width: auto;
}

.ProductSlide_SlideText__EDL9y {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;
}

.ProductSlide_SlideText__EDL9y a,
.ProductSlide_SlideText__EDL9y a:active,
.ProductSlide_SlideText__EDL9y a:visited {
    color: white;
    text-decoration: none;
}

.ProductSlide_SlideText__EDL9y h3 {
    font-size: 28px;
}

.ProductSlide_SlideText__EDL9y p {
    font-size: 15px;
    line-height: 21px;
}

@media (max-width: 1023px) {
    .ProductSlide_Slide__1mX1G {
        flex-flow: column;
        justify-content: center;
    }

    .ProductSlide_ImageContainer__1E1xI {
        order: 1;
        width: 100%;
    }

    .ProductSlide_SlideText__EDL9y {
        order: 2;
        width: 100%;
        text-align: center;
        padding: 0;
    }
}

@media (max-width: 400px) {
    .ProductSlide_ImageContainer__1E1xI {
        width: 60%;
    }

    .ProductSlide_ImageContainer__1E1xI img {
        max-height: 180px;
    }
}
.Portfolio_PortfolioContainer__3wq_Z {
    width: 100%;
    text-align: center;
    padding-top: 30px;
    color: white;
    padding-bottom: 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.Portfolio_CarouselContainer__90y41 {
    width: 935px;
    position: relative;
    padding: 15px 55px 0;
}

.Portfolio_PrevButton__2QEQw {
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: rotate(180deg) translateY(50%);
            transform: rotate(180deg) translateY(50%);
    cursor: pointer;
}

.Portfolio_NextButton__2_Ad_ {
    position: absolute;
    left: 100%;
    top: 50%;
    -webkit-transform: rotate(180);
            transform: rotate(180);
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
    cursor: pointer;
}

.Portfolio_DotNavigationContainer__1ABPv {
    margin-top: 50px; 
}

@media (max-width: 1023px) {
    .Portfolio_CarouselContainer__90y41 {
        width: 90%;
        max-width: 640px;
        margin: auto;
    }
}

@media (max-width: 400px) {
    .Portfolio_CarouselContainer__90y41 {
        padding: 15px 0 0;
    }

    .Portfolio_PrevButton__2QEQw, .Portfolio_NextButton__2_Ad_ {
        top: 27%;
        width: 45px;
        height: 45px;
    }

    .Portfolio_DotNavigationContainer__1ABPv {
        margin-top: 10px;
    }
}
.TimelineElement_TimelineElement__1DsCY {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

}

.TimelineElement_LineAndDot__XsbNE {
    height: 100%;
    width: 1px;

    position: absolute;
    top: 6px;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.3);
}

.TimelineElement_TimelineDot__9ib27 {
    position: absolute;
    /* top: 0; 
    left: 50%; */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.TimelineElement_OnlyTimelineDot__3-Xrf {
    position: absolute;
    top: 6px; 
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.TimelineElement_ElementContent__3mzwJ {
    width: 47%;
    margin-right: 0;
    height: 100%;
    /* background-color: rgba(91, 248, 91, 0.2); */
    text-align: left;
}

.TimelineElement_ElementContent__3mzwJ h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.TimelineElement_ElementContent__3mzwJ p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
} 

.TimelineElement_Left__24q7i {
    align-items: flex-start;
}

.TimelineElement_Left__24q7i .TimelineElement_ElementContent__3mzwJ {
    text-align: right;
}

@media (max-width: 767px) {
    .TimelineElement_ElementContent__3mzwJ {
        margin-bottom: 20px;
        width: 94%;
    }

    .TimelineElement_LineAndDot__XsbNE, .TimelineElement_OnlyTimelineDot__3-Xrf{
        left: 0;
    }

    .TimelineElement_Left__24q7i {
        align-items: flex-end;
    }

    .TimelineElement_Left__24q7i .TimelineElement_ElementContent__3mzwJ {
        text-align: left;
    }
}
.History_HistorySection__a0tnH {
    background-image: -webkit-linear-gradient(top, #2B286C, #4E4997);
    background-image: linear-gradient(to bottom, #2B286C, #4E4997);
    padding: 30px 0 40px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: white;
}

.History_HistoryContent__n6h5n {
    padding-top: 15px;
    width: 80%;
    max-width: 1120px;
    position: relative;

    /* background-color: rgba(80, 20, 20, 0.3); */
}

.History_TimelineElement__2PBJK {
    background-color: transparent;
}
.AboutUsPage_HomePage__1wMlp {
    width: 100%;
    background-color: white;
}
.Input_Input__1ebIb {
    width: 100%;
    padding: 7px 10px;
    box-sizing: border-box;
}

.Input_InputElement__2Kdcg {
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: white;
    font: inherit;
    padding: 10px 15px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    height: 47px;
    resize: none;
    opacity: 0.9;
}

.Input_InputElement__2Kdcg:focus {
    outline: none;
    background-color: #f5f5f5;
}

.Input_InputElement__2Kdcg:placeholder-shown {
    text-transform: uppercase;
    text-align: center;
    
}

.Input_InputElement__2Kdcg::-webkit-input-placeholder {
    opacity: 1;
    color: #4C4A80;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
}

.Input_InputElement__2Kdcg::-ms-input-placeholder {
    opacity: 1;
    color: #4C4A80;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
}

.Input_InputElement__2Kdcg::placeholder {
    opacity: 1;
    color: #4C4A80;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
}

.Input_InputElement__2Kdcg:focus::-webkit-input-placeholder {
    color: transparent;
}

.Input_InputElement__2Kdcg:focus::-ms-input-placeholder {
    color: transparent;
}

.Input_InputElement__2Kdcg:focus::placeholder {
    color: transparent;
}

.Input_InputErr__UKTBk {
    box-shadow: 0px 0px 1px 1px #f01010;
}

.Input_ErrMsg__2SsQs {
    color: #f01010;
}
.ContactData_FormContainer__2hvgB {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 25px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    z-index: 10;
}

.ContactData_FormContainer__2hvgB form {
    width: 100%;
    margin-bottom: 12px;
}

.ContactData_FormContainer__2hvgB h2, 
.ContactData_FormContainer__2hvgB h3 {
    color: #3F3C76;
}

.ContactData_FormContainer__2hvgB form {
    padding-top: 5px;
}

.ContactData_ContactInfoContainer__1iQVT {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
}

.ContactData_SubmitButton__8pnDt {
    background-color: #EF4C32;
    color: #f3f3f3;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 11px 22px;
    margin: 10px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    min-width: 225px;
    border: 2px solid #EF4C32;
    letter-spacing: 1px;
    position: relative;
}

.ContactData_SubmitButton__8pnDt img {
    width: auto;
    position: absolute;
    top: 50%;
    left: 90%;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);
}

@media (max-width: 1023px) {
    .ContactData_FormContainer__2hvgB {
        width: 90%;
    }
}

@media (max-width: 767px) {
    .ContactData_FormContainer__2hvgB {
        margin-top: 10px;
    }

    .ContactData_ContactInfoContainer__1iQVT {
        flex-flow: column;
    }
}
.ContactUsPage_ContactUsPage__1otWA {
    width: 100%;
}
.Modal_Modal__2VMx2 {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #444;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__2VMx2 {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Backdrop_Backdrop__3oMMZ {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.AtlanticBanner_ProductBanner__pusGA {
    width: 100%;
    color: white;
    padding: 75px 0 60px 0;
    position: relative;
    overflow: hidden;
}

.AtlanticBanner_Content__3EoHx {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: auto;
}

.AtlanticBanner_ImageContainer__Qlpt1 {
    width: 42%;
    order: 2;
    max-height: 360px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.AtlanticBanner_ImageContainer__Qlpt1 img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
    -webkit-animation: AtlanticBanner_ship__3dIwU 7s ease-in-out infinite;
            animation: AtlanticBanner_ship__3dIwU 7s ease-in-out infinite;
    /* animation-delay: -6s; */

}

.AtlanticBanner_BannerText__38QIv {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;

    z-index: 2;
}

.AtlanticBanner_BannerText__38QIv a,
.AtlanticBanner_BannerText__38QIv a:visited,
.AtlanticBanner_BannerText__38QIv a:active {
    text-decoration: none;
    color: white;
}

.AtlanticBanner_BannerText__38QIv p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
}

.AtlanticBanner_LeftImage__153p6 .AtlanticBanner_ImageContainer__Qlpt1 {
    order: 1;
}

.AtlanticBanner_LeftImage__153p6 .AtlanticBanner_BannerText__38QIv {
    order: 2;
    text-align: left;
    padding-left: 0;
    padding-right: 5%;
}

.AtlanticBanner_EasyOpenContainer__37UwW {
    position: absolute;
    top: 0%;
    left: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: flex;
}


/*
Wave animation
*/

.AtlanticBanner_Ocean__1UVrh { 
    height: 5%;
    width:100%;
    position:absolute;
    bottom:-5%;
    left:0;
    background: #015871;
    -webkit-transform: scaleY(0.9);
            transform: scaleY(0.9);
    z-index: 1;
  }
  
  .AtlanticBanner_Wave__3qyq- {
    /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;  */
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    -webkit-animation: AtlanticBanner_wave__1w5gR 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
            animation: AtlanticBanner_wave__1w5gR 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  
  .AtlanticBanner_Wave__3qyq-:nth-of-type(2) {
    top: -182px;
    -webkit-animation: AtlanticBanner_wave__1w5gR 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, AtlanticBanner_swell__3fu9w 7s ease -1.25s infinite;
            animation: AtlanticBanner_wave__1w5gR 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, AtlanticBanner_swell__3fu9w 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @-webkit-keyframes AtlanticBanner_wave__1w5gR {
    0% {
      margin-left: calc(50% - 2690px);
    }
    100% {
      margin-left: calc(50% - 4290px);
    }
  }
  
  @keyframes AtlanticBanner_wave__1w5gR {
    0% {
      margin-left: calc(50% - 2690px);
    }
    100% {
      margin-left: calc(50% - 4290px);
    }
  }
  
  @-webkit-keyframes AtlanticBanner_swell__3fu9w {
    0%, 100% {
      -webkit-transform: translate3d(0,-5px,0);
              transform: translate3d(0,-5px,0);
    }
    50% {
      -webkit-transform: translate3d(0,25px,0);
              transform: translate3d(0,25px,0);
    }
  }
  
  @keyframes AtlanticBanner_swell__3fu9w {
    0%, 100% {
      -webkit-transform: translate3d(0,-5px,0);
              transform: translate3d(0,-5px,0);
    }
    50% {
      -webkit-transform: translate3d(0,25px,0);
              transform: translate3d(0,25px,0);
    }
  }

/*
Ship Animation
*/

@-webkit-keyframes AtlanticBanner_ship__3dIwU {
    0%, 100% {
        -webkit-transform: translateY(-55px);
                transform: translateY(-55px);
    }
    50% {
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

@keyframes AtlanticBanner_ship__3dIwU {
    0%, 100% {
        -webkit-transform: translateY(-55px);
                transform: translateY(-55px);
    }
    50% {
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
}

@media (max-width: 1023px) {
    .AtlanticBanner_ProductBanner__pusGA {
        padding: 50px 0 40px 0;
    }
    
    /* .Content {
        width: 90%;
    } */

    .AtlanticBanner_ImageContainer__Qlpt1 {
        max-width: 360px;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }

    .AtlanticBanner_BannerText__38QIv {
        width: 52%;
        padding: 0;
    }

    .AtlanticBanner_LeftImage__153p6 .AtlanticBanner_BannerText__38QIv {
        padding: 0;
    }

    .AtlanticBanner_Ocean__1UVrh {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        bottom: -10%;
    }

    @-webkit-keyframes AtlanticBanner_ship__3dIwU {
        0%, 100% {
            -webkit-transform: translateY(-50px);
                    transform: translateY(-50px);
        }
        50% {
            -webkit-transform: translateY(15px);
                    transform: translateY(15px);
        }
    }

    @keyframes AtlanticBanner_ship__3dIwU {
        0%, 100% {
            -webkit-transform: translateY(-50px);
                    transform: translateY(-50px);
        }
        50% {
            -webkit-transform: translateY(15px);
                    transform: translateY(15px);
        }
    }
}

@media (max-width: 767px) {
    .AtlanticBanner_Ocean__1UVrh {
        display: none;
    }
    
    .AtlanticBanner_ImageContainer__Qlpt1 img {
        -webkit-animation: none;
                animation: none;
    }
}

@media (max-width: 550px) {
    .AtlanticBanner_ProductBanner__pusGA {
        padding: 40px 0 30px 0;
    }

    .AtlanticBanner_EasyOpenContainer__37UwW {
        display: none;
    }

    .AtlanticBanner_Content__3EoHx {
        flex-flow: column;
    }

    .AtlanticBanner_ImageContainer__Qlpt1 {
        order: 1;
        width: 60%;
    }

    .AtlanticBanner_BannerText__38QIv {
        text-align: center;
        order: 2;
        width: 90%;
    }

    .AtlanticBanner_BannerText__38QIv h2 {
        font-size: 35px;
    }

    .AtlanticBanner_LeftImage__153p6 .AtlanticBanner_BannerText__38QIv {
        text-align: center;
    }
}
/* html {
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  html, body {
    height: 100%;
  } */
  
  .TropicalButterfly_content__1BfQ5 {
    align-items: center;
    display: flex;
    justify-content: center;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    -webkit-perspective: 800px;
            perspective: 800px;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  
  .TropicalButterfly_butterfly__53PhE {
    -webkit-animation: TropicalButterfly_hover__Ka2UT 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
            animation: TropicalButterfly_hover__Ka2UT 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-fill-mode: reverse;
            animation-fill-mode: reverse;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(0px);
            transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(0px);
    width: 30px;
  }
  .TropicalButterfly_butterfly__53PhE::before {
    background: #362c13;
    border-radius: 50%;
    content: '';
    display: block;
    height: 110px;
    left: 50%;
    margin-left: -10px;
    outline: 1px solid transparent;
    position: absolute;
    top: -15px;
    -webkit-transform: rotatey(100deg);
            transform: rotatey(100deg);
    width: 20px;
    z-index: 2;
  }
  
  .TropicalButterfly_shadow__2-lI9 {
    -webkit-animation: TropicalButterfly_shadow__2-lI9 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
            animation: TropicalButterfly_shadow__2-lI9 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-fill-mode: reverse;
            animation-fill-mode: reverse;
    background: #000;
    border-radius: 50%;
    display: block;
    height: 10px;
    opacity: 0.1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: translateX(-40px) translateY(100px);
            transform: translateX(-40px) translateY(100px);
    width: 100px;
  }
  
  .TropicalButterfly_wing__1a0CV {
    background: #888;
    display: block;
    opacity: 0.7;
    outline: 1px solid transparent;
    position: absolute;
    top: 0;
  }
  .TropicalButterfly_wing__1a0CV:first-child {
    -webkit-animation: TropicalButterfly_leftflap__hOmKD 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
            animation: TropicalButterfly_leftflap__hOmKD 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-fill-mode: reverse;
            animation-fill-mode: reverse;
    height: 1px;
    left: 0;
    -webkit-transform: rotateY(-20deg);
            transform: rotateY(-20deg);
    -webkit-transform-origin: 700% 50%;
            transform-origin: 700% 50%;
    width: 1px;
    z-index: 3;
  }
  .TropicalButterfly_wing__1a0CV:last-child {
    -webkit-animation: TropicalButterfly_rightflap__Adt1z 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
            animation: TropicalButterfly_rightflap__Adt1z 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-fill-mode: reverse;
            animation-fill-mode: reverse;
    right: 0;
    -webkit-transform: rotateY(200deg);
            transform: rotateY(200deg);
    z-index: 1;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m {
    background: #EF4C32;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m::after {
    background: rgb(251, 110, 89);
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m, .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m::after {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m:first-child {
    height: 70px;
    text-align: center;
    top: 15px;
    -webkit-transform: rotateZ(40deg);
            transform: rotateZ(40deg);
    width: 130px;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m:first-child::after {
    content: '';
    display: inline-block;
    height: 60px;
    left: -30px;
    top: 5px;
    width: 100px;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m:last-child {
    height: 55px;
    -webkit-transform: rotateZ(-40deg);
            transform: rotateZ(-40deg);
    width: 100px;
  }
  .TropicalButterfly_wing__1a0CV .TropicalButterfly_bit__1s82m:last-child::after {
    content: '';
    display: inline-block;
    height: 45px;
    left: -24px;
    top: 5px;
    width: 60px;
    z-index: 1;
  }
  
  @-webkit-keyframes TropicalButterfly_hover__Ka2UT {
    0% {
      -webkit-transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
              transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
    }
    100% {
      -webkit-transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
              transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
    }
  }
  
  @keyframes TropicalButterfly_hover__Ka2UT {
    0% {
      -webkit-transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
              transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
    }
    100% {
      -webkit-transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
              transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
    }
  }
  @-webkit-keyframes TropicalButterfly_shadow__2-lI9 {
    0% {
      -webkit-transform: translateX(-40px) translateY(100px) scale(1, 1);
              transform: translateX(-40px) translateY(100px) scale(1, 1);
    }
    100% {
      -webkit-transform: translateX(-40px) translateY(100px) scale(1.1, 1.1);
              transform: translateX(-40px) translateY(100px) scale(1.1, 1.1);
    }
  }
  @keyframes TropicalButterfly_shadow__2-lI9 {
    0% {
      -webkit-transform: translateX(-40px) translateY(100px) scale(1, 1);
              transform: translateX(-40px) translateY(100px) scale(1, 1);
    }
    100% {
      -webkit-transform: translateX(-40px) translateY(100px) scale(1.1, 1.1);
              transform: translateX(-40px) translateY(100px) scale(1.1, 1.1);
    }
  }
  @-webkit-keyframes TropicalButterfly_leftflap__hOmKD {
    0% {
      -webkit-transform: rotateY(-20deg);
              transform: rotateY(-20deg);
    }
    100% {
      -webkit-transform: rotateY(90deg);
              transform: rotateY(90deg);
    }
  }
  @keyframes TropicalButterfly_leftflap__hOmKD {
    0% {
      -webkit-transform: rotateY(-20deg);
              transform: rotateY(-20deg);
    }
    100% {
      -webkit-transform: rotateY(90deg);
              transform: rotateY(90deg);
    }
  }
  @-webkit-keyframes TropicalButterfly_rightflap__Adt1z {
    0% {
      -webkit-transform: rotateY(200deg);
              transform: rotateY(200deg);
    }
    100% {
      -webkit-transform: rotateY(90deg);
              transform: rotateY(90deg);
    }
  }
  @keyframes TropicalButterfly_rightflap__Adt1z {
    0% {
      -webkit-transform: rotateY(200deg);
              transform: rotateY(200deg);
    }
    100% {
      -webkit-transform: rotateY(90deg);
              transform: rotateY(90deg);
    }
  }


@media (max-width: 767px) {
        .TropicalButterfly_content__1BfQ5 {
                display: none;
        }
}
@media (max-width: 767px) {
    .AlpinSnowfall_SnowfallBackground__IojAl {
        display: none;
    }
}  
  
  .AlpinSnowfall_Snowflake__36oqv {
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(even) {
    background-color: white;
    border-color: #fff;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(odd) {
    background-color: #bbb;
    border-color: #bbb;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(1) {
    top: -91.89600579px;
    left: 53.91734272vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.85447841s linear 2.1248591s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.88310886s ease-in-out 0.38383144s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.85447841s linear 2.1248591s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.88310886s ease-in-out 0.38383144s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(2) {
    top: -126.79125954px;
    left: 53.62221136vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.62388664s linear 1.80441275s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.15047108s ease-in-out 1.17189338s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.62388664s linear 1.80441275s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.15047108s ease-in-out 1.17189338s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(3) {
    top: -59.71978673px;
    left: 38.70695342vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59388036s linear 3.48576319s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.75471348s ease-in-out 1.58160024s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59388036s linear 3.48576319s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.75471348s ease-in-out 1.58160024s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(4) {
    top: -58.99705135px;
    left: 82.56302268vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.88517308s linear 0.56543303s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.13686496s ease-in-out 3.83489004s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.88517308s linear 0.56543303s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.13686496s ease-in-out 3.83489004s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(5) {
    top: -65.73792732px;
    left: 58.19395654vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.620978s linear 1.8829082s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.58002726s ease-in-out 2.46174219s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.620978s linear 1.8829082s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.58002726s ease-in-out 2.46174219s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(6) {
    top: -59.90151898px;
    left: 44.07245404vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.86552256s linear 1.40360384s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.86492341s ease-in-out 3.75346596s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.86552256s linear 1.40360384s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.86492341s ease-in-out 3.75346596s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(7) {
    top: -88.56943809px;
    left: 17.75031549vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.66631156s linear 2.4690489s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.77061672s ease-in-out 4.65232885s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.66631156s linear 2.4690489s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.77061672s ease-in-out 4.65232885s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(8) {
    top: -144.72764798px;
    left: 61.9875859vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.4327709s linear 2.18599154s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.78707809s ease-in-out 1.53982345s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.4327709s linear 2.18599154s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.78707809s ease-in-out 1.53982345s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(9) {
    top: -68.76241232px;
    left: 55.07729087vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59443035s linear 0.37677285s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.8909684s ease-in-out 4.29362935s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59443035s linear 0.37677285s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.8909684s ease-in-out 4.29362935s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(10) {
    top: -73.93387084px;
    left: 91.16151008vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.03208457s linear 4.81421229s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.16190057s ease-in-out 1.08904849s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.03208457s linear 4.81421229s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.16190057s ease-in-out 1.08904849s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(11) {
    top: -116.99470225px;
    left: 14.10153321vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.44172806s linear 4.31805293s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.914315s ease-in-out 3.04431871s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.44172806s linear 4.31805293s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.914315s ease-in-out 3.04431871s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(12) {
    top: -72.74129046px;
    left: 38.45972653vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.03032728s linear 0.54616691s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.2651969s ease-in-out 3.04622193s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.03032728s linear 0.54616691s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.2651969s ease-in-out 3.04622193s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(13) {
    top: -50.0654258px;
    left: 25.27006485vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.65840855s linear 4.99888288s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.56145235s ease-in-out 4.39497306s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.65840855s linear 4.99888288s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.56145235s ease-in-out 4.39497306s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(14) {
    top: -71.43772246px;
    left: 73.26651955vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.07283783s linear 0.14873134s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.50294559s ease-in-out 0.26947427s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.07283783s linear 0.14873134s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.50294559s ease-in-out 0.26947427s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(15) {
    top: -61.25323065px;
    left: 66.88748357vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.53728613s linear 2.60024366s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.06892508s ease-in-out 3.96277947s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.53728613s linear 2.60024366s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.06892508s ease-in-out 3.96277947s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(16) {
    top: -90.76242994px;
    left: 31.75768302vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.78557317s linear 4.2592633s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.75675215s ease-in-out 1.43147614s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.78557317s linear 4.2592633s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.75675215s ease-in-out 1.43147614s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(17) {
    top: -92.67347757px;
    left: 36.72132131vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.07763823s linear 3.8941454s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96680484s ease-in-out 2.96852954s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.07763823s linear 3.8941454s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96680484s ease-in-out 2.96852954s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(18) {
    top: -100.03197565px;
    left: 75.66025736vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.09115658s linear 1.54397749s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.62386662s ease-in-out 0.47570317s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.09115658s linear 1.54397749s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.62386662s ease-in-out 0.47570317s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(19) {
    top: -67.27746897px;
    left: 10.47989741vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.90335519s linear 0.13685823s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.6304789s ease-in-out 1.82825253s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.90335519s linear 0.13685823s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.6304789s ease-in-out 1.82825253s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(20) {
    top: -73.67410979px;
    left: 20.96105421vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.2297806s linear 4.24881653s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.60466904s ease-in-out 2.24123428s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.2297806s linear 4.24881653s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.60466904s ease-in-out 2.24123428s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(21) {
    top: -53.34287892px;
    left: 32.53583863vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.56325742s linear 1.21869329s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.27120591s ease-in-out 3.19165486s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.56325742s linear 1.21869329s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.27120591s ease-in-out 3.19165486s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(22) {
    top: -75.43887371px;
    left: 45.24905521vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59550461s linear 2.1457754s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.17332803s ease-in-out 1.55634966s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59550461s linear 2.1457754s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.17332803s ease-in-out 1.55634966s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(23) {
    top: -50.02952345px;
    left: 77.49650574vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.0933826s linear 0.98722379s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.76601188s ease-in-out 3.55814369s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.0933826s linear 0.98722379s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.76601188s ease-in-out 3.55814369s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(24) {
    top: -118.8196576px;
    left: 15.1250061vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.3228832s linear 4.13842688s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.98922431s ease-in-out 4.94379025s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.3228832s linear 4.13842688s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.98922431s ease-in-out 4.94379025s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(25) {
    top: -106.36059058px;
    left: 18.34143398vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.21279916s linear 3.59650576s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19090228s ease-in-out 0.63202205s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.21279916s linear 3.59650576s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19090228s ease-in-out 0.63202205s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(26) {
    top: -103.9081511px;
    left: 76.04557065vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.06958352s linear 0.82815266s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.58156646s ease-in-out 2.94531432s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.06958352s linear 0.82815266s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.58156646s ease-in-out 2.94531432s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(27) {
    top: -96.56792043px;
    left: 38.66367394vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.00354917s linear 2.10232644s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.68854499s ease-in-out 0.62160544s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.00354917s linear 2.10232644s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.68854499s ease-in-out 0.62160544s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(28) {
    top: -107.03417342px;
    left: 87.83574727vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.77820856s linear 4.81544263s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96793579s ease-in-out 3.05804187s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.77820856s linear 4.81544263s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96793579s ease-in-out 3.05804187s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(29) {
    top: -59.36734149px;
    left: 87.05357291vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.94015694s linear 2.72604542s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.3592206s ease-in-out 4.09207837s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.94015694s linear 2.72604542s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.3592206s ease-in-out 4.09207837s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(30) {
    top: -96.45396155px;
    left: 90.95468561vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.1671584s linear 2.24963713s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.72817532s ease-in-out 0.36107683s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.1671584s linear 2.24963713s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.72817532s ease-in-out 0.36107683s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(31) {
    top: -70.74028783px;
    left: 72.48118152vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.13256301s linear 4.22565595s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.89109571s ease-in-out 0.13266976s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.13256301s linear 4.22565595s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.89109571s ease-in-out 0.13266976s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(32) {
    top: -109.53645185px;
    left: 75.8656403vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.5747906s linear 0.50776629s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.72481062s ease-in-out 2.7663965s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.5747906s linear 0.50776629s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.72481062s ease-in-out 2.7663965s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(33) {
    top: -59.85462265px;
    left: 35.42278784vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.38085051s linear 4.58791699s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.4995816s ease-in-out 3.39198484s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.38085051s linear 4.58791699s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.4995816s ease-in-out 3.39198484s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(34) {
    top: -115.76825443px;
    left: 53.40810286vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59460176s linear 4.51999661s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.82248983s ease-in-out 0.68297339s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59460176s linear 4.51999661s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.82248983s ease-in-out 0.68297339s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(35) {
    top: -114.4489842px;
    left: 67.53525253vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.34607359s linear 4.02450481s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.00968279s ease-in-out 1.36582013s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.34607359s linear 4.02450481s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.00968279s ease-in-out 1.36582013s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(36) {
    top: -122.77680406px;
    left: 16.6843136vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.01564148s linear 2.34619104s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.25680511s ease-in-out 0.00468633s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.01564148s linear 2.34619104s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.25680511s ease-in-out 0.00468633s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(37) {
    top: -142.34512913px;
    left: 87.09081389vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.36892644s linear 3.28190844s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.14963203s ease-in-out 0.01506589s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.36892644s linear 3.28190844s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.14963203s ease-in-out 0.01506589s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(38) {
    top: -148.95235876px;
    left: 45.40879557vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.24060604s linear 1.08959339s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.0931223s ease-in-out 2.55177918s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.24060604s linear 1.08959339s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.0931223s ease-in-out 2.55177918s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(39) {
    top: -75.12537282px;
    left: 95.94237935vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.57004979s linear 3.84890408s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96838672s ease-in-out 2.40878654s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.57004979s linear 3.84890408s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.96838672s ease-in-out 2.40878654s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(40) {
    top: -82.52055056px;
    left: 32.7144111vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.72975047s linear 0.57518944s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.59284153s ease-in-out 0.00492869s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.72975047s linear 0.57518944s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.59284153s ease-in-out 0.00492869s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(41) {
    top: -102.57060255px;
    left: 97.35413535vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.6775553s linear 1.51641412s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.91348205s ease-in-out 0.00188695s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.6775553s linear 1.51641412s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.91348205s ease-in-out 0.00188695s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(42) {
    top: -126.83640525px;
    left: 5.75678608vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.20433021s linear 0.74431962s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.81458002s ease-in-out 3.67585194s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.20433021s linear 0.74431962s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.81458002s ease-in-out 3.67585194s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(43) {
    top: -104.67128806px;
    left: 39.10317096vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.02549757s linear 2.84883457s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.79678709s ease-in-out 1.08559828s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.02549757s linear 2.84883457s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.79678709s ease-in-out 1.08559828s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(44) {
    top: -130.64284274px;
    left: 18.86857233vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.32699008s linear 3.33293111s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.58103587s ease-in-out 2.67086793s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.32699008s linear 3.33293111s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.58103587s ease-in-out 2.67086793s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(45) {
    top: -107.77835251px;
    left: 77.3432497vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.58819811s linear 0.22941595s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.26764544s ease-in-out 4.31627462s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.58819811s linear 0.22941595s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.26764544s ease-in-out 4.31627462s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(46) {
    top: -58.47480062px;
    left: 62.48188553vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.5376304s linear 3.64456969s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.47180271s ease-in-out 1.71666948s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.5376304s linear 3.64456969s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.47180271s ease-in-out 1.71666948s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(47) {
    top: -80.24802302px;
    left: 90.02892345vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.401052s linear 4.71586777s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.08823415s ease-in-out 2.81862705s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.401052s linear 4.71586777s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.08823415s ease-in-out 2.81862705s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(48) {
    top: -126.589372px;
    left: 42.9805416vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.69469368s linear 0.46789756s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15702479s ease-in-out 1.99052153s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.69469368s linear 0.46789756s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15702479s ease-in-out 1.99052153s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(49) {
    top: -143.97193022px;
    left: 14.57213082vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.03878119s linear 4.4465543s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.04785507s ease-in-out 1.57200533s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.03878119s linear 4.4465543s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.04785507s ease-in-out 1.57200533s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(50) {
    top: -69.07080353px;
    left: 4.99686097vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.75948207s linear 0.16501986s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.38731886s ease-in-out 0.27565912s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.75948207s linear 0.16501986s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.38731886s ease-in-out 0.27565912s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(51) {
    top: -115.35709526px;
    left: 98.40463549vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.48070534s linear 2.83739541s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.50995528s ease-in-out 2.87511112s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.48070534s linear 2.83739541s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.50995528s ease-in-out 2.87511112s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(52) {
    top: -70.28277556px;
    left: 56.57505455vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.25410797s linear 1.94447368s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.99091836s ease-in-out 3.08104425s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.25410797s linear 1.94447368s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.99091836s ease-in-out 3.08104425s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(53) {
    top: -99.66988223px;
    left: 8.65447166vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.62861845s linear 2.94837611s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.1195666s ease-in-out 4.62985486s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.62861845s linear 2.94837611s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.1195666s ease-in-out 4.62985486s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(54) {
    top: -84.34617874px;
    left: 40.5656333vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.8806556s linear 2.03212013s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.93062176s ease-in-out 1.55227678s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.8806556s linear 2.03212013s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.93062176s ease-in-out 1.55227678s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(55) {
    top: -125.24419455px;
    left: 75.52067588vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.65099624s linear 2.79925646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.64302479s ease-in-out 1.64169634s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.65099624s linear 2.79925646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.64302479s ease-in-out 1.64169634s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(56) {
    top: -140.32806615px;
    left: 32.11669604vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.10594135s linear 4.25168108s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.00791804s ease-in-out 2.69655787s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.10594135s linear 4.25168108s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.00791804s ease-in-out 2.69655787s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(57) {
    top: -144.00402896px;
    left: 39.43060219vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.5225623s linear 3.67029919s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.4766537s ease-in-out 1.91360713s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.5225623s linear 3.67029919s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.4766537s ease-in-out 1.91360713s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(58) {
    top: -56.7023953px;
    left: 73.13779699vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.60705292s linear 3.0980101s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.5154604s ease-in-out 0.26273098s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.60705292s linear 3.0980101s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.5154604s ease-in-out 0.26273098s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(59) {
    top: -56.1092128px;
    left: 57.88806777vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.08986771s linear 0.31172403s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.04870989s ease-in-out 4.75493536s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.08986771s linear 0.31172403s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.04870989s ease-in-out 4.75493536s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(60) {
    top: -77.00598708px;
    left: 70.34681338vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.67537236s linear 1.12003808s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.93287781s ease-in-out 3.62774448s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.67537236s linear 1.12003808s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.93287781s ease-in-out 3.62774448s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(61) {
    top: -73.21741192px;
    left: 59.57395756vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.91934423s linear 3.54349975s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62486742s ease-in-out 2.24104346s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.91934423s linear 3.54349975s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62486742s ease-in-out 2.24104346s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(62) {
    top: -125.03282449px;
    left: 74.58544582vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.07176684s linear 3.86950284s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.03603588s ease-in-out 4.57758474s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.07176684s linear 3.86950284s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.03603588s ease-in-out 4.57758474s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(63) {
    top: -141.37632989px;
    left: 29.99795463vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.71135893s linear 4.56334222s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.24481444s ease-in-out 3.53033995s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.71135893s linear 4.56334222s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.24481444s ease-in-out 3.53033995s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(64) {
    top: -100.6736978px;
    left: 1.1215167vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.49753088s linear 1.6743702s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.03089649s ease-in-out 4.8385111s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.49753088s linear 1.6743702s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.03089649s ease-in-out 4.8385111s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(65) {
    top: -82.05324823px;
    left: 73.77959688vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.20079952s linear 1.63271561s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.42794122s ease-in-out 2.40789082s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.20079952s linear 1.63271561s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.42794122s ease-in-out 2.40789082s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(66) {
    top: -112.13469118px;
    left: 79.80011465vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.15245635s linear 1.64654246s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.27636796s ease-in-out 4.15296953s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.15245635s linear 1.64654246s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.27636796s ease-in-out 4.15296953s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(67) {
    top: -78.16062921px;
    left: 31.25867339vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.31390324s linear 0.98608101s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.4469254s ease-in-out 1.36369027s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.31390324s linear 0.98608101s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.4469254s ease-in-out 1.36369027s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(68) {
    top: -51.94206544px;
    left: 30.38317801vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.9557925s linear 3.19326194s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.31008568s ease-in-out 2.49350775s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.9557925s linear 3.19326194s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.31008568s ease-in-out 2.49350775s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(69) {
    top: -126.91005728px;
    left: 55.18941281vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.74424623s linear 4.94767524s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.51645347s ease-in-out 1.99621497s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.74424623s linear 4.94767524s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.51645347s ease-in-out 1.99621497s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(70) {
    top: -113.83932311px;
    left: 15.59300615vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.83493048s linear 3.52605579s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.22210339s ease-in-out 2.78787937s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.83493048s linear 3.52605579s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.22210339s ease-in-out 2.78787937s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(71) {
    top: -100.99768196px;
    left: 39.47309855vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.75569079s linear 1.84872751s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.37463193s ease-in-out 2.34717235s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.75569079s linear 1.84872751s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.37463193s ease-in-out 2.34717235s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(72) {
    top: -103.02665091px;
    left: 87.61398722vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.4888389s linear 4.35746723s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.67815295s ease-in-out 0.37170691s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.4888389s linear 4.35746723s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.67815295s ease-in-out 0.37170691s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(73) {
    top: -65.72688283px;
    left: 14.8913051vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.45698327s linear 0.24130712s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.75093604s ease-in-out 3.91684396s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.45698327s linear 0.24130712s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.75093604s ease-in-out 3.91684396s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(74) {
    top: -61.98009627px;
    left: 42.8816707vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59132996s linear 2.66716507s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.22268858s ease-in-out 1.65254696s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59132996s linear 2.66716507s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.22268858s ease-in-out 1.65254696s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(75) {
    top: -126.54859111px;
    left: 26.51213145vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.39274009s linear 3.26568927s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19376586s ease-in-out 4.59850419s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.39274009s linear 3.26568927s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19376586s ease-in-out 4.59850419s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(76) {
    top: -79.70230944px;
    left: 61.21192429vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.78938216s linear 4.4763324s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.99459733s ease-in-out 0.74556437s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.78938216s linear 4.4763324s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.99459733s ease-in-out 0.74556437s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(77) {
    top: -149.81941889px;
    left: 34.01814258vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.79729408s linear 3.88211403s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.77576429s ease-in-out 1.43506565s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.79729408s linear 3.88211403s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.77576429s ease-in-out 1.43506565s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(78) {
    top: -147.57065886px;
    left: 43.01962938vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.26181538s linear 0.08805551s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.80888319s ease-in-out 1.62761307s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.26181538s linear 0.08805551s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.80888319s ease-in-out 1.62761307s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(79) {
    top: -90.48393734px;
    left: 26.23567455vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.95105439s linear 1.78243686s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.2376956s ease-in-out 3.84500686s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.95105439s linear 1.78243686s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.2376956s ease-in-out 3.84500686s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(80) {
    top: -72.64930921px;
    left: 64.14546113vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.06763965s linear 4.17151181s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.18134699s ease-in-out 0.05250472s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.06763965s linear 4.17151181s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.18134699s ease-in-out 0.05250472s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(81) {
    top: -88.91252919px;
    left: 2.12120113vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.50029076s linear 1.80373758s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.86390066s ease-in-out 0.44589638s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.50029076s linear 1.80373758s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.86390066s ease-in-out 0.44589638s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(82) {
    top: -119.34273121px;
    left: 28.70949168vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.07246626s linear 2.9994034s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.04253498s ease-in-out 0.34773418s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.07246626s linear 2.9994034s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.04253498s ease-in-out 0.34773418s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(83) {
    top: -149.71888874px;
    left: 14.74797731vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.94078048s linear 3.09244904s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.72013437s ease-in-out 0.6976426s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.94078048s linear 3.09244904s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.72013437s ease-in-out 0.6976426s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(84) {
    top: -140.21919588px;
    left: 64.41494958vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.58887499s linear 4.19323276s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.10797252s ease-in-out 4.55771447s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.58887499s linear 4.19323276s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.10797252s ease-in-out 4.55771447s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(85) {
    top: -96.85559519px;
    left: 7.335377vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.09683694s linear 0.38476847s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.65964371s ease-in-out 0.23773704s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.09683694s linear 0.38476847s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.65964371s ease-in-out 0.23773704s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(86) {
    top: -130.15853222px;
    left: 21.85199878vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.49628639s linear 1.20182883s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.83871289s ease-in-out 1.25599327s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.49628639s linear 1.20182883s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.83871289s ease-in-out 1.25599327s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(87) {
    top: -106.62421315px;
    left: 41.60030415vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.86912485s linear 0.14067442s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.44535486s ease-in-out 0.22048939s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.86912485s linear 0.14067442s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.44535486s ease-in-out 0.22048939s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(88) {
    top: -134.10033267px;
    left: 31.37861344vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.76172944s linear 0.45690026s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.52035855s ease-in-out 2.93878656s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.76172944s linear 0.45690026s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.52035855s ease-in-out 2.93878656s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(89) {
    top: -121.12305229px;
    left: 13.57486333vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.79770695s linear 1.19250325s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.55553757s ease-in-out 2.1262513s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.79770695s linear 1.19250325s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.55553757s ease-in-out 2.1262513s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(90) {
    top: -129.55036652px;
    left: 35.86690537vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.68722708s linear 3.0187784s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.24301227s ease-in-out 3.05940335s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.68722708s linear 3.0187784s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.24301227s ease-in-out 3.05940335s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(91) {
    top: -126.1056396px;
    left: 91.23957121vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.76796971s linear 1.79081233s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.12875277s ease-in-out 1.45182906s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.76796971s linear 1.79081233s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.12875277s ease-in-out 1.45182906s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(92) {
    top: -148.86969314px;
    left: 94.3242398vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.62945203s linear 0.14396224s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.89947671s ease-in-out 0.91546237s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.62945203s linear 0.14396224s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.89947671s ease-in-out 0.91546237s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(93) {
    top: -99.83432322px;
    left: 97.32105232vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.87630867s linear 1.85325004s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.75499449s ease-in-out 3.48253212s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.87630867s linear 1.85325004s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.75499449s ease-in-out 3.48253212s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(94) {
    top: -112.99859546px;
    left: 67.19123811vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.67747455s linear 0.3426889s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.00737221s ease-in-out 4.71864028s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.67747455s linear 0.3426889s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.00737221s ease-in-out 4.71864028s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(95) {
    top: -140.77133155px;
    left: 82.95104296vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.4217225s linear 0.70344527s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.70406283s ease-in-out 4.13149296s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.4217225s linear 0.70344527s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.70406283s ease-in-out 4.13149296s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(96) {
    top: -108.38405663px;
    left: 98.99259189vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.68255531s linear 3.21934608s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.61316218s ease-in-out 2.17886539s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.68255531s linear 3.21934608s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.61316218s ease-in-out 2.17886539s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(97) {
    top: -113.97666871px;
    left: 22.22450486vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.55948008s linear 1.3446326s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.46253457s ease-in-out 0.08825371s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.55948008s linear 1.3446326s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.46253457s ease-in-out 0.08825371s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(98) {
    top: -143.01344852px;
    left: 74.2847101vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.87008647s linear 0.6034761s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.13626689s ease-in-out 4.34791311s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.87008647s linear 0.6034761s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.13626689s ease-in-out 4.34791311s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(99) {
    top: -123.71677482px;
    left: 59.00617188vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.03404231s linear 3.4152244s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15174324s ease-in-out 4.93333142s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.03404231s linear 3.4152244s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15174324s ease-in-out 4.93333142s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(100) {
    top: -148.08269943px;
    left: 10.26698842vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.16016235s linear 3.35972324s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.78855021s ease-in-out 4.48121227s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.16016235s linear 3.35972324s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.78855021s ease-in-out 4.48121227s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(101) {
    top: -127.97381997px;
    left: 18.74150264vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.49976759s linear 0.51151952s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.46623164s ease-in-out 3.58449118s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.49976759s linear 0.51151952s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.46623164s ease-in-out 3.58449118s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(102) {
    top: -99.04804298px;
    left: 90.20117745vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.70691371s linear 2.31476862s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.94768187s ease-in-out 4.00565816s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.70691371s linear 2.31476862s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.94768187s ease-in-out 4.00565816s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(103) {
    top: -74.93028272px;
    left: 39.41579678vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.18841069s linear 4.30150982s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.23364831s ease-in-out 1.54668057s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.18841069s linear 4.30150982s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.23364831s ease-in-out 1.54668057s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(104) {
    top: -64.81409139px;
    left: 84.44381468vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.47944364s linear 3.37655819s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.41493928s ease-in-out 3.95550885s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.47944364s linear 3.37655819s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.41493928s ease-in-out 3.95550885s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(105) {
    top: -104.15493721px;
    left: 42.14859327vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.20895094s linear 1.01674597s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.66978467s ease-in-out 2.1659681s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.20895094s linear 1.01674597s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.66978467s ease-in-out 2.1659681s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(106) {
    top: -65.15661383px;
    left: 45.68253321vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.05262516s linear 3.69232977s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.53532128s ease-in-out 0.60914131s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.05262516s linear 3.69232977s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.53532128s ease-in-out 0.60914131s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(107) {
    top: -87.14985152px;
    left: 91.4005106vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.00720284s linear 3.66102839s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.74474691s ease-in-out 1.42608467s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.00720284s linear 3.66102839s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.74474691s ease-in-out 1.42608467s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(108) {
    top: -122.13369218px;
    left: 86.69943815vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.6053517s linear 2.68569068s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.52503396s ease-in-out 0.90503121s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.6053517s linear 2.68569068s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.52503396s ease-in-out 0.90503121s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(109) {
    top: -84.90005835px;
    left: 75.32616339vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.11371344s linear 0.95894399s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.30963079s ease-in-out 4.25225355s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.11371344s linear 0.95894399s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.30963079s ease-in-out 4.25225355s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(110) {
    top: -95.25185853px;
    left: 69.69974881vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.79885076s linear 0.79611916s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.95178714s ease-in-out 1.83121811s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.79885076s linear 0.79611916s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.95178714s ease-in-out 1.83121811s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(111) {
    top: -121.29566973px;
    left: 8.55672259vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.35856774s linear 2.79402363s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.46631557s ease-in-out 1.66511925s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.35856774s linear 2.79402363s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.46631557s ease-in-out 1.66511925s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(112) {
    top: -64.42983775px;
    left: 12.80750922vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.1449015s linear 0.08199296s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.60676173s ease-in-out 4.99160475s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.1449015s linear 0.08199296s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.60676173s ease-in-out 4.99160475s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(113) {
    top: -122.30238891px;
    left: 83.27684817vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.76496241s linear 1.05649645s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.74526927s ease-in-out 0.13798488s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.76496241s linear 1.05649645s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.74526927s ease-in-out 0.13798488s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(114) {
    top: -65.93299477px;
    left: 19.87956023vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.36693779s linear 3.44497593s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.08460251s ease-in-out 3.90816466s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.36693779s linear 3.44497593s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.08460251s ease-in-out 3.90816466s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(115) {
    top: -109.43381534px;
    left: 12.38590258vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.97199645s linear 2.55354949s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.86884023s ease-in-out 0.85954545s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.97199645s linear 2.55354949s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.86884023s ease-in-out 0.85954545s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(116) {
    top: -126.66303632px;
    left: 57.6914593vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.63007966s linear 4.51643674s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.67293052s ease-in-out 3.37747178s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.63007966s linear 4.51643674s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.67293052s ease-in-out 3.37747178s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(117) {
    top: -89.42241895px;
    left: 80.63480926vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.72548949s linear 1.64747948s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.41434883s ease-in-out 2.1005935s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.72548949s linear 1.64747948s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.41434883s ease-in-out 2.1005935s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(118) {
    top: -148.33089718px;
    left: 92.73500627vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.21410397s linear 2.07274736s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.92460245s ease-in-out 4.50670597s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.21410397s linear 2.07274736s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.92460245s ease-in-out 4.50670597s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(119) {
    top: -108.14523228px;
    left: 30.09190191vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.16201677s linear 1.59853043s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.10069299s ease-in-out 3.71952935s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.16201677s linear 1.59853043s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.10069299s ease-in-out 3.71952935s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(120) {
    top: -88.8675951px;
    left: 23.95158756vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.6222826s linear 3.60555218s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.47523422s ease-in-out 0.33394415s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.6222826s linear 3.60555218s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.47523422s ease-in-out 0.33394415s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(121) {
    top: -73.48098587px;
    left: 34.74452082vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.66028674s linear 0.24506806s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.84376017s ease-in-out 2.17308357s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.66028674s linear 0.24506806s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.84376017s ease-in-out 2.17308357s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(122) {
    top: -61.22143828px;
    left: 59.1066759vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.2602254s linear 4.73362015s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15683911s ease-in-out 3.68986719s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.2602254s linear 4.73362015s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.15683911s ease-in-out 3.68986719s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(123) {
    top: -81.58685478px;
    left: 4.31597751vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.48845942s linear 3.6606992s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.11231666s ease-in-out 3.2217828s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.48845942s linear 3.6606992s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.11231666s ease-in-out 3.2217828s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(124) {
    top: -60.55225253px;
    left: 96.81649713vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.735505s linear 2.37244859s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.97253458s ease-in-out 3.50668329s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.735505s linear 2.37244859s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.97253458s ease-in-out 3.50668329s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(125) {
    top: -109.09564482px;
    left: 14.65578009vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.9096715s linear 0.96420146s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.12649825s ease-in-out 0.62518436s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.9096715s linear 0.96420146s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.12649825s ease-in-out 0.62518436s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(126) {
    top: -145.40591258px;
    left: 41.94205777vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.92623317s linear 4.81114315s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.40639442s ease-in-out 1.83187335s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.92623317s linear 4.81114315s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.40639442s ease-in-out 1.83187335s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(127) {
    top: -142.11503314px;
    left: 3.68936293vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.18245717s linear 4.35496678s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.97977711s ease-in-out 0.36946047s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.18245717s linear 4.35496678s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.97977711s ease-in-out 0.36946047s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(128) {
    top: -147.93773689px;
    left: 33.38689972vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.24194532s linear 2.29148895s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.72694065s ease-in-out 2.76699231s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.24194532s linear 2.29148895s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.72694065s ease-in-out 2.76699231s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(129) {
    top: -92.63913823px;
    left: 96.9058649vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.62757366s linear 3.1467094s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.5635536s ease-in-out 3.67562203s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.62757366s linear 3.1467094s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.5635536s ease-in-out 3.67562203s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(130) {
    top: -97.53523654px;
    left: 22.89344807vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.57427575s linear 2.21162053s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.60927252s ease-in-out 3.98825309s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.57427575s linear 2.21162053s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.60927252s ease-in-out 3.98825309s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(131) {
    top: -129.72578937px;
    left: 18.32780975vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.15154825s linear 2.20196579s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.82658127s ease-in-out 1.32136151s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.15154825s linear 2.20196579s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.82658127s ease-in-out 1.32136151s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(132) {
    top: -98.02252517px;
    left: 75.02786572vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.91349583s linear 4.4007248s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.85890261s ease-in-out 4.29206098s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.91349583s linear 4.4007248s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.85890261s ease-in-out 4.29206098s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(133) {
    top: -102.49224538px;
    left: 85.54506439vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.95755072s linear 1.31027646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.79267946s ease-in-out 1.05954542s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.95755072s linear 1.31027646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.79267946s ease-in-out 1.05954542s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(134) {
    top: -128.96675299px;
    left: 84.36251445vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.83692666s linear 4.85869683s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.62951942s ease-in-out 1.99689348s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.83692666s linear 4.85869683s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.62951942s ease-in-out 1.99689348s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(135) {
    top: -129.99325118px;
    left: 66.62133434vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.43045473s linear 0.06966526s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.73770032s ease-in-out 4.97295651s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.43045473s linear 0.06966526s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.73770032s ease-in-out 4.97295651s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(136) {
    top: -55.49450246px;
    left: 55.14579428vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.51464349s linear 2.23349117s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.89094828s ease-in-out 2.1981021s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.51464349s linear 2.23349117s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.89094828s ease-in-out 2.1981021s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(137) {
    top: -131.75296606px;
    left: 99.32244808vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.083909s linear 0.89712086s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.35530588s ease-in-out 3.61544188s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.083909s linear 0.89712086s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.35530588s ease-in-out 3.61544188s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(138) {
    top: -63.60245998px;
    left: 55.03137567vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.16438526s linear 3.47116006s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.51209814s ease-in-out 3.1390034s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.16438526s linear 3.47116006s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.51209814s ease-in-out 3.1390034s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(139) {
    top: -67.64753236px;
    left: 4.0604085vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.47560691s linear 1.41221852s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.06091076s ease-in-out 3.62513348s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.47560691s linear 1.41221852s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.06091076s ease-in-out 3.62513348s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(140) {
    top: -135.88232631px;
    left: 78.36499548vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.79248668s linear 0.2593816s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.83510297s ease-in-out 3.87156763s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.79248668s linear 0.2593816s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.83510297s ease-in-out 3.87156763s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(141) {
    top: -147.06164737px;
    left: 75.00054453vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.58354133s linear 1.62123056s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.43851825s ease-in-out 2.97673847s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.58354133s linear 1.62123056s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.43851825s ease-in-out 2.97673847s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(142) {
    top: -89.15467248px;
    left: 94.25705135vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.41199004s linear 4.78399161s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.51215623s ease-in-out 1.00847092s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.41199004s linear 4.78399161s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.51215623s ease-in-out 1.00847092s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(143) {
    top: -66.49293059px;
    left: 15.37991758vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.09453227s linear 4.21790516s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.481707s ease-in-out 4.66575524s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.09453227s linear 4.21790516s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.481707s ease-in-out 4.66575524s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(144) {
    top: -69.15706805px;
    left: 27.69687021vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.38739908s linear 1.60474166s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.40768523s ease-in-out 4.14070059s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.38739908s linear 1.60474166s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.40768523s ease-in-out 4.14070059s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(145) {
    top: -97.13917065px;
    left: 5.56005139vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.02852336s linear 3.1996404s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.01973791s ease-in-out 4.40313757s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.02852336s linear 3.1996404s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.01973791s ease-in-out 4.40313757s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(146) {
    top: -114.92427977px;
    left: 36.97882865vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.59889021s linear 3.97027398s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.43347056s ease-in-out 3.54755675s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.59889021s linear 3.97027398s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.43347056s ease-in-out 3.54755675s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(147) {
    top: -74.04632402px;
    left: 91.86661733vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.76324249s linear 1.9047338s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.01183876s ease-in-out 2.16968622s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.76324249s linear 1.9047338s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.01183876s ease-in-out 2.16968622s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(148) {
    top: -133.77617906px;
    left: 22.96080786vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.41229919s linear 4.71675884s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.67823119s ease-in-out 3.23031587s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.41229919s linear 4.71675884s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.67823119s ease-in-out 3.23031587s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(149) {
    top: -73.66744161px;
    left: 22.27113408vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.91554486s linear 2.28643657s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.81485807s ease-in-out 0.54737425s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.91554486s linear 2.28643657s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.81485807s ease-in-out 0.54737425s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(150) {
    top: -127.24164075px;
    left: 87.54597301vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.92136616s linear 4.75610248s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.27617731s ease-in-out 1.41047544s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.92136616s linear 4.75610248s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.27617731s ease-in-out 1.41047544s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(151) {
    top: -83.6530412px;
    left: 57.18218492vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.84906767s linear 3.46535441s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.62181792s ease-in-out 3.09439692s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.84906767s linear 3.46535441s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.62181792s ease-in-out 3.09439692s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(152) {
    top: -114.52990615px;
    left: 17.17449143vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.00092955s linear 4.34202593s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.43500041s ease-in-out 4.88997563s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.00092955s linear 4.34202593s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.43500041s ease-in-out 4.88997563s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(153) {
    top: -128.81713135px;
    left: 47.52272354vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.09336467s linear 2.06570378s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.97615057s ease-in-out 4.4566312s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.09336467s linear 2.06570378s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.97615057s ease-in-out 4.4566312s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(154) {
    top: -70.81583632px;
    left: 69.84111024vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.95800864s linear 1.0562413s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.45396348s ease-in-out 2.92282987s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.95800864s linear 1.0562413s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.45396348s ease-in-out 2.92282987s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(155) {
    top: -98.04482433px;
    left: 29.55374897vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.05502202s linear 3.4920691s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.54934939s ease-in-out 0.75560049s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.05502202s linear 3.4920691s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.54934939s ease-in-out 0.75560049s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(156) {
    top: -140.31529587px;
    left: 94.27030274vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.4508976s linear 1.79961761s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.22640941s ease-in-out 0.9188372s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.4508976s linear 1.79961761s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.22640941s ease-in-out 0.9188372s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(157) {
    top: -67.75900331px;
    left: 21.80767978vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.77886279s linear 1.42223512s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.36508176s ease-in-out 1.53103157s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.77886279s linear 1.42223512s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.36508176s ease-in-out 1.53103157s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(158) {
    top: -89.56570373px;
    left: 80.08530429vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.85793033s linear 2.8243948s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.11640499s ease-in-out 0.04215734s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.85793033s linear 2.8243948s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.11640499s ease-in-out 0.04215734s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(159) {
    top: -72.28112689px;
    left: 5.82107666vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.43963348s linear 2.96210224s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.15025124s ease-in-out 1.55889119s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.43963348s linear 2.96210224s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.15025124s ease-in-out 1.55889119s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(160) {
    top: -145.66800758px;
    left: 4.31137733vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.2784076s linear 4.64775024s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.26963685s ease-in-out 4.34861216s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.2784076s linear 4.64775024s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.26963685s ease-in-out 4.34861216s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(161) {
    top: -56.13797836px;
    left: 99.54480219vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.00459025s linear 0.84549565s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19644039s ease-in-out 4.58453161s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.00459025s linear 0.84549565s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.19644039s ease-in-out 4.58453161s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(162) {
    top: -84.52290122px;
    left: 64.98183994vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.48183706s linear 4.17982884s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.48758944s ease-in-out 0.43027891s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.48183706s linear 4.17982884s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.48758944s ease-in-out 0.43027891s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(163) {
    top: -149.26435373px;
    left: 71.68748787vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.98651051s linear 1.53023396s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.16317785s ease-in-out 2.68307794s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.98651051s linear 1.53023396s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.16317785s ease-in-out 2.68307794s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(164) {
    top: -97.17970455px;
    left: 16.72574732vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.88509072s linear 3.89852672s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.06607971s ease-in-out 2.66933632s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.88509072s linear 3.89852672s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.06607971s ease-in-out 2.66933632s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(165) {
    top: -117.93826715px;
    left: 85.12943149vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.6216891s linear 1.89047193s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.63333789s ease-in-out 3.37276094s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.6216891s linear 1.89047193s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.63333789s ease-in-out 3.37276094s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(166) {
    top: -58.7474079px;
    left: 40.05412402vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.24540066s linear 3.74549406s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.94176958s ease-in-out 4.7762044s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.24540066s linear 3.74549406s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.94176958s ease-in-out 4.7762044s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(167) {
    top: -84.27491717px;
    left: 92.11207478vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.53437992s linear 4.21555825s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.26233148s ease-in-out 3.13008694s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.53437992s linear 4.21555825s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.26233148s ease-in-out 3.13008694s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(168) {
    top: -56.02629893px;
    left: 61.03034483vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.83131959s linear 2.19588336s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.11773985s ease-in-out 1.25391934s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.83131959s linear 2.19588336s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.11773985s ease-in-out 1.25391934s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(169) {
    top: -52.74054365px;
    left: 9.17271201vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.41260882s linear 1.92596116s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.79983752s ease-in-out 4.50098092s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.41260882s linear 1.92596116s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.79983752s ease-in-out 4.50098092s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(170) {
    top: -143.62224147px;
    left: 5.53118298vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.38170076s linear 4.3589398s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.91769592s ease-in-out 2.41137274s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.38170076s linear 4.3589398s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.91769592s ease-in-out 2.41137274s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(171) {
    top: -107.08306364px;
    left: 68.40520036vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.86339081s linear 1.94501851s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.81521068s ease-in-out 2.11967588s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.86339081s linear 1.94501851s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.81521068s ease-in-out 2.11967588s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(172) {
    top: -56.61620131px;
    left: 32.3948023vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.5839486s linear 0.96240022s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.02713418s ease-in-out 1.95918432s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.5839486s linear 0.96240022s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.02713418s ease-in-out 1.95918432s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(173) {
    top: -112.21283344px;
    left: 35.44474728vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.93357384s linear 3.18987966s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.68282307s ease-in-out 4.99170943s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.93357384s linear 3.18987966s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.68282307s ease-in-out 4.99170943s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(174) {
    top: -76.37780271px;
    left: 93.55334914vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.10992283s linear 4.79109801s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.23619515s ease-in-out 3.71743045s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.10992283s linear 4.79109801s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.23619515s ease-in-out 3.71743045s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(175) {
    top: -141.44080533px;
    left: 83.09375484vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.45479277s linear 3.52207619s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.13924025s ease-in-out 4.43281207s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.45479277s linear 3.52207619s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.13924025s ease-in-out 4.43281207s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(176) {
    top: -145.17576751px;
    left: 96.53886201vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.14131213s linear 2.0658282s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.54892493s ease-in-out 4.15278305s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.14131213s linear 2.0658282s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.54892493s ease-in-out 4.15278305s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(177) {
    top: -93.86976791px;
    left: 85.12261323vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.02540682s linear 2.72224744s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.47607067s ease-in-out 4.79796057s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.02540682s linear 2.72224744s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.47607067s ease-in-out 4.79796057s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(178) {
    top: -140.65467959px;
    left: 30.0912899vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.29870053s linear 4.84003867s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.14723725s ease-in-out 3.26574696s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.29870053s linear 4.84003867s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.14723725s ease-in-out 3.26574696s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(179) {
    top: -145.94913797px;
    left: 88.90930363vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.30023069s linear 4.79400898s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.65640899s ease-in-out 0.10894124s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.30023069s linear 4.79400898s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.65640899s ease-in-out 0.10894124s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(180) {
    top: -100.10415657px;
    left: 43.55825521vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.18599517s linear 3.74844693s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.12386031s ease-in-out 1.03323695s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.18599517s linear 3.74844693s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.12386031s ease-in-out 1.03323695s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(181) {
    top: -104.39023853px;
    left: 69.98184397vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.01004733s linear 0.38206053s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.32722257s ease-in-out 1.63770693s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.01004733s linear 0.38206053s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.32722257s ease-in-out 1.63770693s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(182) {
    top: -81.03656175px;
    left: 51.47672299vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.57405361s linear 2.96730829s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.39084698s ease-in-out 3.48116208s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.57405361s linear 2.96730829s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.39084698s ease-in-out 3.48116208s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(183) {
    top: -120.02911898px;
    left: 21.70480951vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.96630825s linear 3.65851418s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.1261414s ease-in-out 1.63728909s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.96630825s linear 3.65851418s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.1261414s ease-in-out 1.63728909s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(184) {
    top: -66.27435522px;
    left: 29.7473837vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.31637713s linear 3.50923655s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.09725853s ease-in-out 1.09742517s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.31637713s linear 3.50923655s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.09725853s ease-in-out 1.09742517s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(185) {
    top: -109.64940407px;
    left: 45.96574155vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.63081856s linear 0.17520256s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.29767131s ease-in-out 0.01347867s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.63081856s linear 0.17520256s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.29767131s ease-in-out 0.01347867s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(186) {
    top: -51.9797435px;
    left: 43.88297725vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.45500776s linear 1.06901628s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.92510003s ease-in-out 4.38156301s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.45500776s linear 1.06901628s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.92510003s ease-in-out 4.38156301s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(187) {
    top: -79.08147855px;
    left: 74.81376788vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.47071747s linear 1.42296082s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.23403492s ease-in-out 3.31049062s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.47071747s linear 1.42296082s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.23403492s ease-in-out 3.31049062s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(188) {
    top: -106.48716933px;
    left: 31.82723775vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.48630196s linear 0.08930774s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.62515041s ease-in-out 0.74030764s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.48630196s linear 0.08930774s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.62515041s ease-in-out 0.74030764s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(189) {
    top: -133.92884548px;
    left: 33.69771775vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.58198393s linear 3.5264138s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.92077621s ease-in-out 0.06694165s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.58198393s linear 3.5264138s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.92077621s ease-in-out 0.06694165s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(190) {
    top: -125.21038766px;
    left: 19.86289517vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.51878125s linear 4.74548963s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62556619s ease-in-out 3.12027732s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.51878125s linear 4.74548963s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62556619s ease-in-out 3.12027732s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(191) {
    top: -144.07052012px;
    left: 99.75055584vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.69107824s linear 0.63410935s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.21231444s ease-in-out 2.62049796s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.69107824s linear 0.63410935s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.21231444s ease-in-out 2.62049796s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(192) {
    top: -121.67408384px;
    left: 27.12137533vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.241678s linear 0.24434646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.87780454s ease-in-out 1.22084093s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.241678s linear 0.24434646s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.87780454s ease-in-out 1.22084093s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(193) {
    top: -70.67362905px;
    left: 55.1727163vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.38655341s linear 4.01838944s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.83668781s ease-in-out 3.98960599s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.38655341s linear 4.01838944s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.83668781s ease-in-out 3.98960599s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(194) {
    top: -69.67825795px;
    left: 36.66998345vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 10.6134217s linear 4.30533172s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.57258191s ease-in-out 2.95814518s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 10.6134217s linear 4.30533172s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.57258191s ease-in-out 2.95814518s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(195) {
    top: -50.92515564px;
    left: 9.3094956vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.26682962s linear 1.43170078s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62990871s ease-in-out 1.30682612s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.26682962s linear 1.43170078s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.62990871s ease-in-out 1.30682612s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(196) {
    top: -77.93964616px;
    left: 7.9215054vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.79780442s linear 2.49694547s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.59618969s ease-in-out 2.09296748s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.79780442s linear 2.49694547s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 12.59618969s ease-in-out 2.09296748s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(197) {
    top: -103.47974893px;
    left: 18.39978529vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.95285507s linear 4.30989992s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.37977349s ease-in-out 4.65625274s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.95285507s linear 4.30989992s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.37977349s ease-in-out 4.65625274s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(198) {
    top: -136.5181137px;
    left: 90.66308205vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 11.13270009s linear 4.64906748s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.45080812s ease-in-out 2.39867507s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 11.13270009s linear 4.64906748s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 13.45080812s ease-in-out 2.39867507s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(199) {
    top: -134.81933097px;
    left: 11.81183059vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 9.48528155s linear 1.35473191s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.84300606s ease-in-out 3.39361192s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 9.48528155s linear 1.35473191s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 15.84300606s ease-in-out 3.39361192s infinite alternate;
  }
  .AlpinSnowfall_Snowflake__36oqv:nth-of-type(200) {
    top: -111.99704061px;
    left: 52.33342718vw;
    -webkit-animation: AlpinSnowfall_snowfall__2zebL 8.93352454s linear 1.32339744s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.39970133s ease-in-out 2.63089044s infinite alternate;
            animation: AlpinSnowfall_snowfall__2zebL 8.93352454s linear 1.32339744s infinite normal, AlpinSnowfall_snowflake-shake__1Ea-a 14.39970133s ease-in-out 2.63089044s infinite alternate;
  }
  @-webkit-keyframes AlpinSnowfall_snowfall__2zebL {
    to {
      top: 100vh;
    }
  }
  @keyframes AlpinSnowfall_snowfall__2zebL {
    to {
      top: 100vh;
    }
  }
  @-webkit-keyframes AlpinSnowfall_snowflake-shake__1Ea-a {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    33% {
      -webkit-transform: translateX(-5vh);
              transform: translateX(-5vh);
    }
    100% {
      -webkit-transform: translateX(5vh);
              transform: translateX(5vh);
    }
  }
  @keyframes AlpinSnowfall_snowflake-shake__1Ea-a {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    33% {
      -webkit-transform: translateX(-5vh);
              transform: translateX(-5vh);
    }
    100% {
      -webkit-transform: translateX(5vh);
              transform: translateX(5vh);
    }
  }
  
.ProductBanner_ProductBanner__1cR1u {
    width: 100%;
    color: white;
    padding: 75px 0 60px 0;
    position: relative;
    overflow: hidden;
}

.ProductBanner_Content__19V6G {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    margin: auto;
    position: relative;
    z-index: 1;
}

.ProductBanner_ImageContainer__Xsl1Q {
    width: 42%;
    order: 2;
    max-height: 360px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
}

.ProductBanner_ImageContainer__Xsl1Q img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
    
}

.ProductBanner_TropicalButterflyContainer__3xZHh {
    position: absolute;
    bottom: 25%;
    left: 10%;
}

.ProductBanner_BannerText__3qt7j {
    width: 45%;
    order: 1;
    text-align: right;
    padding-left: 5%;
}

.ProductBanner_BannerText__3qt7j a,
.ProductBanner_BannerText__3qt7j a:visited,
.ProductBanner_BannerText__3qt7j a:active {
    text-decoration: none;
    color: white;
}

.ProductBanner_BannerText__3qt7j p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
}

.ProductBanner_LeftImage__1PSZw .ProductBanner_ImageContainer__Xsl1Q {
    order: 1;
}

.ProductBanner_LeftImage__1PSZw .ProductBanner_BannerText__3qt7j {
    order: 2;
    text-align: left;
    padding-left: 0;
    padding-right: 5%;
}

.ProductBanner_EasyOpenContainer__bodaQ {
    position: absolute;
    top: 0%;
    left: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: flex;
}

@media (max-width: 1023px) {
    .ProductBanner_ProductBanner__1cR1u {
        padding: 50px 0 40px 0;
    }
    
    .ProductBanner_Content__19V6G {
        width: 90%;
    }

    .ProductBanner_ImageContainer__Xsl1Q {
        max-width: 360px;
    }

    .ProductBanner_BannerText__3qt7j {
        width: 52%;
        padding: 0;
    }

    .ProductBanner_LeftImage__1PSZw .ProductBanner_BannerText__3qt7j {
        padding: 0;
    }
}

@media (max-width: 550px) {
    .ProductBanner_ProductBanner__1cR1u {
        padding: 40px 0 30px 0;
    }

    .ProductBanner_EasyOpenContainer__bodaQ {
        display: none;
    }

    .ProductBanner_Content__19V6G {
        flex-flow: column;
    }

    .ProductBanner_ImageContainer__Xsl1Q {
        order: 1;
        width: 60%;
    }

    .ProductBanner_BannerText__3qt7j {
        text-align: center;
        order: 2;
        width: 90%;
    }

    .ProductBanner_BannerText__3qt7j h2 {
        font-size: 35px;
    }

    .ProductBanner_LeftImage__1PSZw .ProductBanner_BannerText__3qt7j {
        text-align: center;
    }
}
.ProductNavBar_ProductNavBar__1eCKz {
    height: 70px;
    width: 100%;
    padding: 0 14%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 1;
    position: relative;
}

.ProductNavBar_BackLink__1r2g- {
    height: 70px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: #4C4A80;
    cursor: pointer;
}

.ProductNavBar_BackLink__1r2g- img {
    margin-right: 15px;
}

.ProductNavBar_BreadCrumbs__3Vqfe {
    color: #888;
    font-size: 14px;
}

.ProductNavBar_BreadCrumbDivider__rzU6Y {
    white-space: pre;
}

.ProductNavBar_BreadCrumbs__3Vqfe a,
.ProductNavBar_BreadCrumbs__3Vqfe a:visited,
.ProductNavBar_BreadCrumbs__3Vqfe a:active {
    text-decoration: none;
    color: #888;
    display: inline-block;
    
}

.ProductNavBar_CurrentPageBreadCrumb__b9b6f {
    display: inline-block;
}

.ProductNavBar_CurrentPageBreadCrumb__b9b6f a,
.ProductNavBar_CurrentPageBreadCrumb__b9b6f a:visited,
.ProductNavBar_CurrentPageBreadCrumb__b9b6f a:active {
    text-decoration: none;
    color: #4C4A80;
    font-weight: 500;
}

.ProductNavBar_ContactUsLink__3MorM {
    color: #888;
    font-size: 14px;
}

.ProductNavBar_ContactUsLink__3MorM img {
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .ProductNavBar_ProductNavBar__1eCKz {
        padding: 0 2%;
    }
}

@media (max-width: 1023px) {
    .ProductNavBar_ProductNavBar__1eCKz {
        padding: 0 6%;
    }

    .ProductNavBar_BreadCrumbs__3Vqfe {
        display: none;
    }
}

@media (max-width: 480px) {
    .ProductNavBar_ContactUsLink__3MorM {
        display: none;
    }
}
.SizeModule_SizeModule__azVx7 {
    height: 100%;
    width: 31vw;
    max-width: 480px;
    margin: 0 10px;
    background-color: white;
    box-shadow: 0 0 20px rgba(42, 42, 42, 0.3);
    box-sizing: border-box;

    text-align: center;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SizeModule_ImageContainer__3dBjt {
    height: 21vw;
    display: flex;
    max-height: 250px;
}

.SizeModule_ImageContainer__3dBjt img {
    margin: auto;
    max-width: 100%;
    max-height: 80%;
    height: auto;
}

.SizeModule_SizeModule__azVx7 p {
    line-height: 20px;
    color: #666;
}

.SizeModule_SizeModule__azVx7 a {
    text-decoration: none;
}

@media (max-width: 767px) {
    .SizeModule_SizeModule__azVx7 {
        width: 90%;
        margin-bottom: 10px;
    }

    .SizeModule_ImageContainer__3dBjt {
        height: 42vw;
    }
}

.SingleProductBanner_BannerWithModules__37x70 {
    padding-bottom: 60px;
}

.SingleProductBanner_SingleProductBanner__1xhe5 {
    width: 100%;
    background-color: white;
    color: white;
    padding: 25px 0 120px 0;
    /* margin-bottom: 415px; */
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.SingleProductBanner_Content__uGsPj {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 1110px;
    padding: 0 55px;
    margin: auto;
    height: 420px;
    position: relative;
}

.SingleProductBanner_ImageContainer__3wV7E {
    width: 45%;
    order: 1;
    max-height: 420px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.SingleProductBanner_ImageContainer__3wV7E img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    
}

.SingleProductBanner_BannerText__3hoUV {
    width: 50%;
    order: 2;
    text-align: left;
}

.SingleProductBanner_PictogramContainer__2h1b_ {
    padding-top: 5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 30px;
}

.SingleProductBanner_PictogramContainer__2h1b_ img {
    height: auto;
}

.SingleProductBanner_PictogramText__YK3dV {
    margin-left: 20px;
}

.SingleProductBanner_PictogramText__YK3dV p {
    margin: 0;
    line-height: 18px;
}

.SingleProductBanner_TechImgContainer__3Wucd {
    display: flex;
    flex-flow: row;
}

.SingleProductBanner_LayersIconContainer__1sATR {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.SingleProductBanner_LayersIconContainer__1sATR img {
    height: auto;
}

.SingleProductBanner_LayersIconText__1HlUM {
    font-size: 18px;
    margin-left: 20px;
}

.SingleProductBanner_LayersIconText__1HlUM p {
    margin: 0;
    line-height: 20px;
}

.SingleProductBanner_PrevButton__3FK7D {
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: rotate(180deg) translateY(50%);
            transform: rotate(180deg) translateY(50%);
    
}

.SingleProductBanner_NextButton__u9N5P {
    position: absolute;
    left: 100%;
    top: 50%;
    -webkit-transform: rotate(180);
            transform: rotate(180);
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
}

.SingleProductBanner_SizesContainer__2dsTQ {
    height: 420px;
    width: 100%;
    /* background-color: rgba(255, 0, 0, 0.4); */
    position: relative;
    z-index: 2;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    margin-top: -80px;
}

@media (max-width: 1100px) {
    .SingleProductBanner_ImageContainer__3wV7E {
        width: 48%;
    }

    .SingleProductBanner_PictogramContainer__2h1b_ {
        flex-flow: column;
        align-items: flex-start;
    }

    .SingleProductBanner_PictogramText__YK3dV {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 1023px) {
    .SingleProductBanner_BannerText__3hoUV h2 {
        margin-right: -30px;
    }

    .SingleProductBanner_LayersIconContainer__1sATR {
        flex-flow: column;
        align-items: center;
    }

    .SingleProductBanner_LayersIconText__1HlUM {
        margin-left: 0;
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .SingleProductBanner_BannerWithModules__37x70 {
        padding-bottom: 0;
    }

    .SingleProductBanner_Content__uGsPj {
        padding: 0;
        flex-flow: column;
        height: auto;
    }

    .SingleProductBanner_ImageContainer__3wV7E {
        width: 60%;
        max-height: 170px;
    }

    .SingleProductBanner_ImageContainer__3wV7E img {
        max-height: 170px;
        width: auto;
    }

    .SingleProductBanner_BannerText__3hoUV {
        width: 100%;
        text-align: center;
    }

    .SingleProductBanner_BannerText__3hoUV h2 {
        margin-right: 0;
    }

    .SingleProductBanner_PictogramContainer__2h1b_ {
        flex-flow: column;
        align-items: center;
    }

    .SingleProductBanner_TechImgContainer__3Wucd {
        justify-content: center;
    }

    .SingleProductBanner_PrevButton__3FK7D, .SingleProductBanner_NextButton__u9N5P {
        top: 25%;
        width: 45px;
        height: 45px;
    }

    .SingleProductBanner_SizesContainer__2dsTQ {
        height: auto;
        flex-flow: column;

        margin-top: -90px;
    }
}
.SingleProductPage_ContactUsContainer__2o48z {
    width: 100%;
}

@media (max-width: 767px) {
    .SingleProductPage_ContactUsContainer__2o48z {
        display: none;
    }
}
