/* 
Green: #70B258
Blue: #41B3E4
Orange: #FF672E
Violet: #4E4997
Dark Violet: #2B286C
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Fira sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1200px; */
  background-color: #f8f8f8;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

body img {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-family: 'Lobster';
  font-size: 2.625em;
  margin-top: 0.4762em;
  margin-bottom: 0.5952em;
  font-weight: 400;
}

h3 {
  font-family: 'Lobster';
  font-size: 1.625em;
  margin-top: 0.7692em;
  margin-bottom: 0.7692em;
  font-weight: initial;
}

@media(max-width: 1023px) {
  body, html {
    font-size: 15px;
  }
}

/* @media(max-width: 767px) {
  body, html {
    font-size: 14px;
  }
} */