.ContactUs {
    background-color: #2B286C;
    padding: 0 8%;
    padding-top: 30px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.ContactUs a {
    text-decoration: none;
}

.ImageBox {
    order: 1;
    margin-top: 40px;
    background-image: radial-gradient(circle at 50% 100%, #789FC5 5%, transparent 60%);
}

.ImageBox img {
    display: block;
    max-width: 402px;
    height: auto;
    margin: auto;
}

.ContentBox {
    color: white;
    text-align: left;
    padding-left: 10%;
    padding-bottom: 40px;
    max-width: 700px;
    order: 2;
}

.ContentBox p {
    line-height: 160%;
    /* font-size: 16px; */
    margin: 0;
    font-family: 'Fira sans';
}

.ButtonsBox {
    margin-right: -20%;
}

@media( min-width: 1301px ) {
    .ContactUs {
        padding: 0 12%;
    }
}

@media ( min-width: 1024px) {
    .MobileOnly {
        display: none;
    }
}

@media ( max-width: 1023px) {
    .DesktopOnly {
        display: none;
    }

    .ContactUs {
        background-color: #2B286C;
        background-image: none;
        flex-flow: column;
        align-items: center;
    }

    .ContentBox {
        text-align: center;
        order: 1;
        padding-left: 0;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .ButtonsBox {
        margin: auto;
    }

    .ImageBox {
        order: 2;
        width: 80%;
        margin-top: 0;
    }

    .ImageBox img {
        width: 100%;
        max-width: 300px;
        height: auto;
    }
}

@media (max-width: 767px) {
    .ImageBox {
        display: none;
    }
}