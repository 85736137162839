.Button {
    background-color: transparent;
    border: none;
    color: #f3f3f3;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 13px 22px;
    margin: 10px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    min-width: 225px;
    border: 2px solid;
    border-color: #f3f3f3cc;
    letter-spacing: 1px;
    font-family: 'Fira sans';
}


