.BannerWithModules {
    padding-bottom: 60px;
}

.SingleProductBanner {
    width: 100%;
    background-color: white;
    color: white;
    padding: 25px 0 120px 0;
    /* margin-bottom: 415px; */
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.Content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 1110px;
    padding: 0 55px;
    margin: auto;
    height: 420px;
    position: relative;
}

.ImageContainer {
    width: 45%;
    order: 1;
    max-height: 420px;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.ImageContainer img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    
}

.BannerText {
    width: 50%;
    order: 2;
    text-align: left;
}

.PictogramContainer {
    padding-top: 5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 30px;
}

.PictogramContainer img {
    height: auto;
}

.PictogramText {
    margin-left: 20px;
}

.PictogramText p {
    margin: 0;
    line-height: 18px;
}

.TechImgContainer {
    display: flex;
    flex-flow: row;
}

.LayersIconContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.LayersIconContainer img {
    height: auto;
}

.LayersIconText {
    font-size: 18px;
    margin-left: 20px;
}

.LayersIconText p {
    margin: 0;
    line-height: 20px;
}

.PrevButton {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
    
}

.NextButton {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: rotate(180);
    transform: translate(-100%, -50%);
}

.SizesContainer {
    height: 420px;
    width: 100%;
    /* background-color: rgba(255, 0, 0, 0.4); */
    position: relative;
    z-index: 2;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    margin-top: -80px;
}

@media (max-width: 1100px) {
    .ImageContainer {
        width: 48%;
    }

    .PictogramContainer {
        flex-flow: column;
        align-items: flex-start;
    }

    .PictogramText {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 1023px) {
    .BannerText h2 {
        margin-right: -30px;
    }

    .LayersIconContainer {
        flex-flow: column;
        align-items: center;
    }

    .LayersIconText {
        margin-left: 0;
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .BannerWithModules {
        padding-bottom: 0;
    }

    .Content {
        padding: 0;
        flex-flow: column;
        height: auto;
    }

    .ImageContainer {
        width: 60%;
        max-height: 170px;
    }

    .ImageContainer img {
        max-height: 170px;
        width: auto;
    }

    .BannerText {
        width: 100%;
        text-align: center;
    }

    .BannerText h2 {
        margin-right: 0;
    }

    .PictogramContainer {
        flex-flow: column;
        align-items: center;
    }

    .TechImgContainer {
        justify-content: center;
    }

    .PrevButton, .NextButton {
        top: 25%;
        width: 45px;
        height: 45px;
    }

    .SizesContainer {
        height: auto;
        flex-flow: column;

        margin-top: -90px;
    }
}