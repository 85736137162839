.AboutUsSection {
    background-image: linear-gradient(to right, #2B286C, #4E4997);
    position: relative;
    padding-bottom: 75px;
}

.ButtonArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

