.LanguageSelect {
    background-color: #404072;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    position: relative;    
    font-size: 13px;
    font-weight: 500;
}

.SelectedContainer {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.FlagContainer {
    margin-right: 10px;
}

.ArrowDown {
    width: 20%;
    color: #898AA8;
}

.CountryList {
    position: absolute;
    top: 100%;
    background-color: white;
    color: white;
    width: 100%;
    border: 1px groove #403C77;
    border-radius: 3px;
    background-color: #403C77;
}

.Option {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 80%;
    padding: 10px 0 10px 17px;
    cursor: pointer;
}

.CountryList .Option {
    width: 100%;
}

.CountryList .Option:hover {
    background-color: #3B3867;
}

@media (max-width: 767px) {
    .DesktopAndTablet {
        display: none;
    }

    .LanguageSelect {
        font-weight: 400;
    }

    .FlagContainer {
        margin: auto;
    }

    .Selected {
        width: 100%;
        padding: 0;
        font-size: 16px;
    }

    .CountryList {
        width: 100vw;
        height: calc(83vh - 60px);
        background-color: #403C77;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-radius: 0;
        color: white;
        padding-top: 15px;
    }

    .CountryList .Option {
        justify-content: center;
        font-size: 21px;

    }

    .CountryList .Option .FlagContainer {
        margin: 0;
        margin-right: 10px;
        font-size: 16px;
    }

    .CountryList .Option:hover {
        background-color: rgba(42, 42, 42, 0.2);
    }
}

/* @media (min-width: 768px) {
    .MobileOnly {
        display: none;
    }
} */