.Overlay {
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, #2B286C, #5550A4);
    overflow-y: hidden;
    transition: all 0.5s ease-out;
    height: 83%;
}

.OverlayContent {
    position: relative;
    top: 60px;
    width: 100%;
    margin-top: 25px;
}

/* .Overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.Overlay a:hover, .Overlay a:focus {
    color: #f1f1f1;
} */

.Overlay .CloseBtnContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    line-height: 45px;
    cursor: pointer;
}

.Logo {
    position: absolute;
    top: 0;
    left: 50%;

    height: 60px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    transform: translateX(-50%);
}

.Logo a {
    padding: 0;
    font-size: initial;
}

.Logo img {
    margin: auto;
    max-height: 48px;
}


@media screen and (max-height: 450px) {
  .Overlay a {font-size: 16px}
  .Overlay .CloseBtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (min-width: 768px) {
    .Overlay {
        display: none;
    }
}