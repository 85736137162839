.AboutUsText {
    display: block;
    color: white;
    text-align: center;
    width: 80%;
    max-width: 768px;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
}

.AboutUsText p {
    line-height: 160%;
    font-size: 16px;
    margin: 0;
}

