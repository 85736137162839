.ContactUsText {
    display: block;
    color: white;
    /* width: 80%;
    max-width: 768px;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px; */
}

.ContactUsText p {
    line-height: 160%;
    margin: 0;
}

