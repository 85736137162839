.Tab {
    height: 70px;
    margin: 0 40px;
    display: flex;
    align-items: center;
    color: #2B286C;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    cursor: pointer;
}

.Pointer {
    position: absolute;
    width: 120%;
    height: 10px;
    background-color: red;
    top:100%;
    left: -10%;
    transform: translateY(-25%);

    clip-path: polygon(0 0, 100% 0, 100% 50%, 54% 50%, 50% 100%, 46% 50%, 0 50%);

    background-image: linear-gradient(to right, #FE6C35, #F0533A);
}

@media (max-width: 767px) {
    .Tab {
        margin: 0 15px;
    }

    .Pointer {
        width: 100%;
        left: 0;
    }
}