.SizeModule {
    height: 100%;
    width: 31vw;
    max-width: 480px;
    margin: 0 10px;
    background-color: white;
    box-shadow: 0 0 20px rgba(42, 42, 42, 0.3);
    box-sizing: border-box;

    text-align: center;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ImageContainer {
    height: 21vw;
    display: flex;
    max-height: 250px;
}

.ImageContainer img {
    margin: auto;
    max-width: 100%;
    max-height: 80%;
    height: auto;
}

.SizeModule p {
    line-height: 20px;
    color: #666;
}

.SizeModule a {
    text-decoration: none;
}

@media (max-width: 767px) {
    .SizeModule {
        width: 90%;
        margin-bottom: 10px;
    }

    .ImageContainer {
        height: 42vw;
    }
}
