.VideoThumbnail {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.Background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
    width: auto;
    height: auto;
}

.ThumbnailContent {
    width: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #2B286C;
    text-align: center;
}

.PlayVideoContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.PlayVideoContainer p {
    display: inline-block;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 4px;
    font-size: 15px;
}

.PlayButton {
    margin: 0 20px;
    cursor: pointer;
}

@media(max-width: 480px) {
    .VideoThumbnail {
        overflow: hidden;
    }

    .PlayButton {
        transform: scale(0.7);
    }

    .NewLine {
        display: block;
    }
}